import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router";

import Title from "../../../components/Title";
import SubTitle from "../../../components/SubTitle";
import Paragraph from "../../../components/Paragraph";
import { TextField, Paper } from "@mui/material";
import MyBackdrop from "../../../components/Backdrop";
import Button from "../../../components/Button";
import Popup from "../../../components/Pop-up";
import { ClientContext } from "../../../context/client";

import { iquiteiApi, iquiteiNegotiations } from '../../../services';

import { maskCpf } from "../../../utils/document.format";


function Home(props) {
  const { setClient, client } = useContext(ClientContext);
  let history = useHistory();
  let location = useLocation();

  const [loading, setLoading] = useState(false);
  const [helperText, setHelperText] = useState(false);
  const [error, setError] = useState(false);
  const [cpf, setCpf] = useState("");
  const [inCooldown, toggleCooldown] = useState(false);

  const [proposal, setProposal] = useState({
    campaignPosition: 0,
    actualInstallment: 0,
    quantInstallment: 0,
    valueInstallment: '',
    creditorName: '',
    idProposal: 0,
  });

  const confirmateCpf = async (event) => {
    try {
    let config = {
      digits: cpf,
      document: client.cpf,
    };

    await iquiteiApi.postRequest(`/partners/${client.partnerId}/v2/client/validate`, config)

    config = {
      id: client.clientId,
    };
    await iquiteiApi.postRequest(`/partners/${client.partnerId}/v2/confirmation/preventive`, config);
        const path = {
          pathname: "/ticket",
          state: {
            client: client,
            locate: location.pathname,
          },
        };

        history.push(path);
    } catch (error) {
      setError(true);
      if (cpf.length < 3) {
        setHelperText("Favor digitar os 3 digitos do CPF");
      } else {
        setHelperText("CPF não confere.");
      }
    }
  };

  const navigatePage = (event, locate) => {
    const path = {
      pathname: `/${locate}`,
      state: {
        client: client,
        locate: location.pathname,
      },
    };
    history.push(path);
  };

  const getClientById = async (id) => {
    try {
      const response = await iquiteiApi.getRequest(
        `/partners/1/v2/client/preventive${id}`
      );
      const data = response.data.data[0];
      
      setClient({...client, ...data});

      return response.data.data[0];
    } catch (error) {
      throw error;
    }
  };

  const getClientInfo = async (creditorId, shipmentId, positionShipment, cpf) => {
    try {
      const response = await iquiteiNegotiations.getRequest(
        `/partners/1/proposals/${creditorId}/${shipmentId}/${positionShipment}/${cpf}`
      );

      const data = response.data.data;
      setProposal({...client, ...data});
    } catch (error) {
      throw error;
    }
  };

  const formatMessage = () => (
    `Olá ${proposal?.name}, para baixar o boleto referente à parcela de número
    ${proposal?.actualInstallment}/${proposal?.quantInstallment}, de valor ${proposal?.valueInstallment} com o credor
    ${proposal?.creditorName}, confirme os dígitos que estão ocultos do seu CPF no campo abaixo.`
  );

  useEffect(() => {
    sessionStorage.setItem('clientPath', location.pathname);
    (async () => {
      setLoading(true);
      try {
        const clientResponse = await getClientById(location.pathname.replace("/p", ""));
        const { creditorId, campaignId, campaignPosition, cpf, clientId, partnerId } = clientResponse;
        await Promise.all([
          getClientInfo(creditorId, campaignId, campaignPosition, cpf ),
          iquiteiApi.putRequest(
            `/partners/${partnerId}/v2/client/preventive/${clientId}/access`
          ),
        ]);
        setLoading(false);
        if (history.location.state && history.location.state.cooldown) {
          toggleCooldown(true);
          history.location.state.cooldown = false;
        }
      } catch (error) {
        const locationBody = {
          pathname: "/404",
          state: {
            lastState: "/",
            description: "Erro ao buscar cliente pelo ID",
            myThrow: error.message,
            status: error.response?.status,
            payload: error.request?.responseURL,
            origin: location,
          },
        };

        setLoading(false);

        history.push(locationBody);
      }
    })();

    // eslint-disable-next-line
  }, [history, location]);

  //definido o tipo de dOocumento inicializado CPF OU CNPJ
  let tipoDado = "";
  let tamanhoDado = maskCpf(client.cpf) || '0';
  //usamos 14 para fazer a contagem, pois entram os caracteres add pela mascara. Ex: 052.xxx.721-89
  tipoDado =
    tamanhoDado.length === 14 ? (tipoDado = "CPF") : (tipoDado = "CNPJ");

  console.log(client, 'CLIENT')

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px auto' }}>
      {inCooldown && (
        <Popup>
          <span>
            Por favor, aguardar 30 segundos antes de tentar novamente.
          </span>
          <Button
            text="Sair"
            color="secondary"
            variant="contained"
            maxWidth="300px"
            size="large"
            onClick={() => toggleCooldown(false)}
            marginTop="20px"
          />
        </Popup>
      )}
      <MyBackdrop open={loading} />

      <Paper
        elevation={3}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "16px",
        }}
      >
        <div className="home-preventive">
          <Paragraph fontSize={'17px'} text={formatMessage()} paragraph={true}/>
        </div>
        <SubTitle fontSize={'15px'} text={'Esta etapa é importante para proteger seu sigilo bancário'} paragraph={true}/>
        <Title text={`${tipoDado} ${maskCpf(client?.cpf)}`} paragraph={true} />
        <TextField
          error={error}
          helperText={helperText}
          onChange={(event) => setCpf(event.target.value)}
          value={cpf}
          label="Insira os 3 digitos"
          size="small"
          inputProps={{
            type: "tel",
            maxLength: 3,
            style: { fontSize: 20, textAlign: "center" },
          }}
          style={{ maxWidth: 300, width: "100%", margin: "8px" }}
        />

        <Button
          text="CONFIRMAR CPF"
          color="primary"
          variant="contained"
          maxWidth="300px"
          onClick={(event) => confirmateCpf(event)}
          size="large"
          marginTop={16}
        />
      </Paper>

      <Button
        text={`Sobre a ${client?.sigla}`}
        color="secondary"
        variant="contained"
        maxWidth="300px"
        size="large"
        marginTop="40px"
        onClick={(event) => navigatePage(event, "about")}
      />

    </div>
  );
}

export default Home;
