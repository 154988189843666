import smileyIcon from "../../../../../assets/icons/smiley.svg";
import recurrencyIcon from "../../../../../assets/icons/recurrency.svg";
import walletIcon from "../../../../../assets/icons/wallet.svg";

import { useHistory } from "react-router-dom";

import { Container } from './style';

const reasonsCard = [
  {
    icon: smileyIcon,
    title: 'Retirar restrições do seu nome', 
    description: 'Renegociar dívidas ajudará você a retirar as restrições do seu nome e voltar a ter uma vida financeira mais organizada e tranquila.' 
  },
  {
    icon: walletIcon, 
    title: 'Controle financeiro', 
    description: 'Dívidas são uma bola de neve, então aproveite todas as oportunidades de retirar as restrições do seu nome e viva uma vida tranquila!'
  },
  {
    icon: recurrencyIcon, 
    title: 'Recuperar crédito', 
    description: 'Quando seu nome tem restrição fica dificil conseguir crédito para investir no que é mais importante.Por isso, negocie hoje mesmo e dê o primeiro passo em direção a uma nova vida financeira!'
  }
];

export default function ReasonsHome({ url, creditor }) {
  const history = useHistory();

  return (
    <Container creditor={creditor} className="reasons">
      <span className="reasons-title font-dossis-bold">
        MOTIVOS PARA <span className="fill">NEGOCIAR SUA DÍVIDA</span>
      </span>

      <div className="reason-list">
        {reasonsCard.map((i, index) => (
          <div className="reason-card" key={index}>
            <div className="reason-icon">
              <img src={i.icon} alt="smiley" />
            </div>

            <span className="reason-title font-dossis-semibold">{i.title}</span>

            <span className="reason-description font-dossis-medium">
              {i.description}
            </span>
          </div>
        ))}
      </div>
      
      <div className="button-container">
        <button
        style={{
          width: '50%'
        }}
          className="button-negotiate font-dossis-semibold"
          onClick={() =>
            history.push(url)
          }
        >
          QUERO NEGOCIAR COM DESCONTO!
        </button>
      </div>
    </Container>
  );
};
