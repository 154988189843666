import styled from "styled-components";

export const Container = styled.div`
    flex: 1;
    margin: 15px auto;

    button {
      background-color:  ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].primary : theme.creditor['0'].primary};
    }
`;
export const Loading = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgb(38 15 99);
  display: flex;
  justify-content: center;
  align-items: center;
  
`;

export const LoadingImg = styled.img`
  width: 20%!important;
  animation: blinkAnimation 1s ease 0s infinite normal forwards;

  @keyframes blinkAnimation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.2;
    }

    100% {
      opacity: 1;
    }
  }
`;