import { useContext } from 'react'
import Paragraph from '../Paragraph'
import { documentCovert } from '../../utils/document.format';

import { ClientContext } from '../../context/client';

export default function Footer() {
    const { client } = useContext(ClientContext);

    const name = client.partnerSocialName;
    const document = client && client.partnerCNPJ ? documentCovert(client.partnerCNPJ) : client.partnerCNPJ;

    return (
        <div style={{ width: '100%' }}>
            <Paragraph
                align="center"
                fontSize="14px"
                text={`${name} ${document}`}
                paragraph
                className="font-dossis-medium"
            />
            <div style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
            <img
                src="/images/selosseguranca.png"
                alt="seloseguranca"
                style={{ width: '40%' }}
            />
            </div>
            <div 
              onClick={(event) => {
                window.location.href = 'https://www.vhgsolucoes.com.br'
            }}
            
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: "#D9D9D9",
                padding: '10px',
                cursor: 'pointer',
                width: '100%',
            }}>
                <Paragraph 
                    fontSize="12px"
                    text='iQuitei. Um produto VHG Soluções - CNPJ:41.320.364/0001-36'
                    color="black"
                />
            </div>
        </div>
    )
}
