import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  padding: 0 219px;

  @media screen and (max-width: 1450px) {
    padding: 0 65px;
  }

  background-color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].primary : theme.creditor['0'].primary};

  .container {
    padding: 65px 0;
  }

  .depositions-title {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-transform: uppercase;

    color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].whiteText : theme.creditor['0'].whiteText};
    &.destaque {
      color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].third : theme.creditor['0'].third};
    }
  }

  .deposition-list {
    display: flex;
 
    @media screen and (max-width: 1550px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 1250px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .deposition-card {
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    align-items: flex-end;
    
    background: #FFFFFF;

    box-shadow: 0px 11px 20px rgba(21, 8, 56, 0.2);
    border-radius: 8px;
    
    width: 255px;
    
    padding: 20px 50px;
    margin: 0 10px;
    margin-top: 20px;
    margin-bottom: 72px;

 
    @media screen and (max-width: 1550px) {
      width: auto;
    }

    @media screen and (max-width: 1250px) {
      width: 100%;
    }
  }

  svg {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 700;
    font-size: 100px;

    fill: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].primary : theme.creditor['0'].primary};
  }

  .deposition-description {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;

    color: #000000;

    padding: 10px 0;
  }

    .deposition-name {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;

      color: #000000;
    }
`;
