import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";

import Title from "../../../components/Title";
import Paragraph from "../../../components/Paragraph";
import { Paper } from "@mui/material";
import MyBackdrop from "../../../components/Backdrop";
import Button from "../../../components/Button";
import Popup from "../../../components/Pop-up";

import { iquiteiApi, iquiteiNegotiations } from '../../../services';


function App(props) {
  let history = useHistory();
  let location = useLocation();

  const [loading, setLoading] = useState(false);
  const [inCooldown, toggleCooldown] = useState(false);
  const [client, setClient] = useState({
    sigla: '',
    campaignId: 0,
    cpf: "",
    partnerId: 1,
    creditorId: 0,
    clientId: 939424,
    clientName: '',
    creditor: 0,
  });

  const [proposal, setProposal] = useState({
    digitableLine: '',
    link: '',
    termoAcordo: '',
  });

  const navigatePage = (event, locate) => {
    const path = {
      pathname: `/${locate}`,
      state: {
        client: client,
        locate: location.pathname,
      },
    };
    history.push(path);
  };

  const getClientInfo = async (creditorId, shipmentId, positionShipment, cpf) => {
    try {
      const response = await iquiteiNegotiations.getRequest(
        `/partners/${client.partnerId}/proposals/ticket/${creditorId}/${shipmentId}/${positionShipment}/${cpf}`
      );

      const data = response.data.data;
      setProposal(data);
    } catch (error) {
      throw error;
    }
  };

  const updateClientTicketDownloaded = async () => {
    try {
      await iquiteiApi.putRequest(
        `/partners/${client.partnerId}/v2/client/preventive/${client.clientId}/ticket`
      );
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const clientResponse = location.state.client;
        setClient(clientResponse);

        const { creditorId, campaignId, campaignPosition, cpf } = clientResponse;
        await getClientInfo(creditorId, campaignId, campaignPosition, cpf );

        setLoading(false);
        if (history.location.state && history.location.state.cooldown) {
          toggleCooldown(true);
          history.location.state.cooldown = false;
        }
      } catch (error) {
        const locationBody = {
          pathname: "/404",
          state: {
            lastState: "/",
            description: "Erro ao buscar cliente pelo ID",
            myThrow: error.message,
            status: error.response?.status,
            payload: error.request?.responseURL,
            origin: location,
          },
        };

        setLoading(false);

        history.push(locationBody);
      }
    })();

    // eslint-disable-next-line
  }, [history, location]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px auto', width: '80%' }}>
      {inCooldown && (
        <Popup>
          <span>
            Por favor, aguardar 30 segundos antes de tentar novamente.
          </span>
          <Button
            text="Sair"
            color="secondary"
            variant="contained"
            maxWidth="300px"
            size="large"
            onClick={() => toggleCooldown(false)}
            marginTop="20px"
          />
        </Popup>
      )}
      <MyBackdrop open={loading} />

      <Paper
        elevation={3}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "16px",
        }}
      >
        <Paragraph fontSize={'17px'} text={'Muito obrigado por confirmar suas informações!<br/> Por favor, selecione uma opção abaixo:'}/>

        <div className="ticket-opcoes">
          <div className="linha-digitavel">
            <Title text={'Linha digitável:'} fontSize={'12px'}/>
            <Paragraph fontSize={'17px'} text={proposal.digitableLine}/>
          </div>
          <Button
            text="Clique para copiar"
            color="primary"
            variant="contained"
            maxWidth="300px"
            onClick={() => {
              navigator.clipboard.writeText(proposal.digitableLine)
              updateClientTicketDownloaded()
            }}
            size="small"
            marginTop={16}
          />

          <Title text={'Ou então:'} fontSize={'12px'}/>

          <Button
            text="Clique para baixar o boleto"
            color="primary"
            variant="contained"
            maxWidth="300px"
            onClick={() => {
              window.open(proposal.link, '_blank')
              updateClientTicketDownloaded()
            }}
            size="small"
            marginTop={16}
          />
        </div>

        <Title text={'Para ver o termo do acordo:'} fontSize={'12px'}/>
        <Button
          text="Clique para baixar o termo do acordo"
          color="primary"
          variant="contained"
          maxWidth="300px"
          onClick={() => { window.open(proposal.termoAcordo, '_blank') }}
          size="small"
          marginTop={16}
        />
      </Paper>

      <Button
        text={`Sobre a ${client?.sigla}`}
        color="secondary"
        variant="contained"
        maxWidth="300px"
        size="large"
        marginTop="40px"
        onClick={(event) => navigatePage(event, "about")}
      />

    </div>
  );
}

export default App;
