/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from 'react'

//components
import Paragraph from '../../../components/Paragraph'
import MyBackDrop from '../../../components/Backdrop'
import BasicButtonGroup from '../../../components/GroupButton'
import SliderProposal from '../../../components/Slider'
import Button from '../../../components/Button'
import ProposalDeny from '../../../components/ProposalDeny';

import { iquiteiApi, iquiteiNegotiations } from '../../../services';

import { ClientContext } from '../../../context/client';

//mui
import { Paper } from '@mui/material'

import { formatValue } from '../../../utils/value.format'
import formatMessage from '../../../utils/formatMessage';

import { useHistory, useLocation } from 'react-router';
import { Title } from '../../../components'

import {Loading, LoadingImg} from '../Home/styled'
import iquiteiLogo from '../../../assets/images/iquitei-logo.svg'
import { Container } from './styled'

function Proposal() {
  let history = useHistory();
  const { setClient, client } = useContext(ClientContext);
  
  const selectDebt = useLocation().state.debt
  const session = useLocation().state.proposal
  const [loading, setLoading] = useState(true)
  const [typeNegotiate, setTypeNegotiate] = useState(2);

  const [proposal, setProposal] = useState({
    NegotiationOffers: [],
    updatedValue: 0
  });

  const [selectedProposal, setSelectedProposal] = useState(0);

  const [denyProposal, setDenyProposal] = useState(false);

  const selectProposal = async (item, lastDueDate) => {
    await iquiteiApi.postRequest(`/partners/${client.partnerId}/v2/proposal`, {
      id: client.clientId
    })
    item.proposal.lastDueDate = lastDueDate
    const location = {
      pathname: '/confirmateProposal',
      state: {
        item,
        client,
      }
    }
    history.push(location)
  }

  useEffect(() => {
  if (client?.partnerId !== 0) {
    (async () => {
      try {
          setLoading(true)
          const generatedProposal = await iquiteiNegotiations.postRequest(`/partners/${client.partnerId}/generateProposal/${client.creditorId}/${client.idQuery}?shipmentId=${client.shipmentId}&positionShipment=${client.positionShipment}`, {selectDebt, session } )
          setProposal(generatedProposal.data.data)
          setLoading(false)
      } catch (error) {
        setLoading(false);
        let location = '';
        if (error.response && error.response.status ===410) {
          location = {
            pathname: `/expiredLink`,
            state: {
              client
            }
          }
        } else if (error.response && error.response.status === 309) {
          location = { pathname: '/', state: { cooldown: true } };
        } else if (error.response && error.response.status === 401) {
          location = { pathname: '/debts' };
        } else if (error.response && error.response.status === 402) {
          setClient({...client, error: error.response.data.error});
          location = { pathname: '/error' };
        } else {

        location = {
          pathname: '/404',
          state: {
            lastState: '/',
            description: 'Erro ao buscar cliente pelo ID',
            myThrow: error.message,
            status: error.response?.status,
            payload: error.request?.responseURL
          }
          }
        }
        history.push(location)
      }
    })();
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, history])
    
    const returnOrigem = () => {
    if(formatMessage('{origem}', client) === 'undefined'){
      return formatMessage('{Origem}', client)
    }else{
      return formatMessage('{origem}', client)
    }
    }

    const renderText = () => {
      if (client?.creditor === 2 || client?.creditor === 3) {
        return (
          <Paragraph
            text={`Constam débitos em aberto junto ao CPF referente ao(s) contrato(s) oriundo(s) do(a) ${returnOrigem()}, e o saldo atualizado dessa dívida é de <b>${formatValue(
              proposal?.updatedValue
            )}.</b>`}
            paragraph={true}
            align="left"
            className='font-dossis-medium'
          />
        );
      } else {
        const isMultipleContracts = proposal?.contractNumbers?.length > 1;
        const contractNumbers = `<br/> ${proposal?.contractNumbers?.map(
          (number) => `<b>${number}</b> `
        )}`;
  
        return (
          <Paragraph className='font-dossis-medium'
            text={`Você está com uma pendência financeira referente ${
              isMultipleContracts ? "aos" : "ao"
            } ${
              isMultipleContracts ? "contratos" : "contrato"
            } ${contractNumbers}</b> <br/> ${
              isMultipleContracts ? "oriundos" : "oriundo"
            } do <b>${
              proposal?.debtDescription ? proposal?.debtDescription : ""
            }</b> e o saldo atualizado dessa dívida é de <b>${formatValue(
              proposal?.updatedValue
            )}.</b>`}
            paragraph={true}
            align="left"
            />
            
        );
      }
    };

    const [mobile, setMobile] = useState(false);
    useEffect(() => {
    if(window.innerWidth < 444) {
      setMobile(true);
    } else {
      setMobile(false);
    }
    },[window.innerWidth]);
    
  return (  <>
          {loading && (
            <Loading className="loading">
              <LoadingImg src={iquiteiLogo} alt="iquitei-logo"/>
            </Loading>
          )}
   
    <Container creditor={client?.creditorId}>
      <MyBackDrop open={loading} />
      {denyProposal && (
        <ProposalDeny popup close={setDenyProposal}/>
      )}
      <Paper
        elevation={3}
        style={{
          alignItems: "center",
          display: 'flex',
          flexDirection: 'column',
          padding: '40px',
          margin: '0 5px',
          width: mobile && '320px'
        }}
      >
        <div style={{ alignSelf: "flex-start" }}>
          <Title
            align="left"
            text={client.clientName + ','}
            paragraph
            fontFamily='Dosis-bold'
          />

          {renderText()}

          <Paragraph align="left" text={`Clique no plano de pagamento abaixo para obter outras informações e emitir o boleto. Proposta válida até <b>${proposal?.lastDueDate}</b>.`} paragraph={true} className='font-dossis-medium'/>
        </div>

          <BasicButtonGroup
            typeNegotiate={typeNegotiate}
            setTypeNegotiate={setTypeNegotiate}
            maxWidth="70%"
            fontFamily='Dossis-bold'
            creditor={client?.creditorId}
          />

          <SliderProposal
            proposal={proposal}
            typeNegotiate={typeNegotiate}
            setSelectedProposal={setSelectedProposal}
            fontFamily= 'Dosis-medium'
            creditor={client?.creditorId}
          /> 

          <Button
            className={"select-proposal font-dossis-bold"}
            text="SELECIONAR PROPOSTA"
            color="primary"
            variant="contained"
            size="large"
            onClick={() => selectProposal(selectedProposal, proposal.lastDueDate)}
            fontFamily= 'Dosis-semibold'
            
          />
      </Paper>
      
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%',fontFamily:'Dosis-semibold'}}>
        {client.showDenyNegotiationButton && (
          <Button
            text="Negociar em outro momento"
            color="secondaryFog"
            onClick={() => setDenyProposal(true)}
            size="large"
            maxWidth="95%"
            margin="0 auto"
            fontFamily='Dosis-semibold'
          />
        )}

        <a className="about" onClick={() => history.push("/about")}>
          <Title fontFamily='Dosis-semibold' text={`Sobre a ${client?.partnerName}`} align="center" margin="20px 0"/>
        </a>
      </div>
    </Container>
    </>
  )
}

export default Proposal
