import { LINK } from './iquiteiApi.service';

export const insertDenyProposal = async (partnerId, body) => {
  try {
    const response = await LINK.post(`/partners/${partnerId}/v2/proposalDeny`, body);

    return response.data;
  } catch (error) {
    console.error(error);
    return 'ERRO';
  }
};

export const updateDenyProposal = async (partnerId, id, body) => {
  try {
    const response = await LINK.put(`/partners/${partnerId}/v2/proposalDeny/${id}`, body);

    return response.data;
  } catch (error) {
    console.error(error);
    return '';
  }
};
