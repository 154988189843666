/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useContext } from "react";
import { TextField, Paper } from "@mui/material";
import { useHistory, useLocation } from "react-router";
import { Container } from "./styled";

import {
  Popup,
  Button,
  Backdrop,
  Paragraph,
  Title,
  Box,
  Tooltip,
} from '../../../components';

import { iquiteiApi } from "../../../services";
import { ClientContext } from '../../../context/client';

import { convertCPF } from "../../../utils/document.format";

export default function ConfirmateCPF() {
  const { client, setClient } = useContext(ClientContext);
  let history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [helperText, setHelperText] = useState(false);
  const [error, setError] = useState(false);
  const [cpf, setCpf] = useState("");
  const [inCooldown, toggleCooldown] = useState(false);


  useEffect(() => {
    setLoading(false);
    sessionStorage.setItem('clientPath', location?.pathname);
    (async () => {
      await iquiteiApi.putRequest(
        `/partners/${client.partnerId}/v2/site/client/${client.clientId}`,
        {
          step: 'INPUT_CPF',
        },
      );
    })();
  }, []);

  useEffect(() => { 
    const cpfFormatted = cpf.replace(/[^\d]/g, "");

    if (cpfFormatted.length === 11) {
      setError(false);
      setHelperText(false);
    }
  }, [cpf]);

  const onChangeCPFValue = (value) => {
    return setCpf(convertCPF(value));
  }

  const confirmateCpf = async () => {
    const cpfFormatted = cpf.replace(/[^\d]/g, "");
    try {

      if (cpfFormatted.length !== 11) {
        throw Error('Tamanho do CPF invalido');
      }

      await iquiteiApi.putRequest(
        `/partners/${client.partnerId}/v2/site/client/${client.clientId}`,
        {
          step: 'SUBMITED_CPF',
          cpf: cpfFormatted.toString(),
        },
      );

      setClient({
        ...client,
        idQuery: cpfFormatted,
      })
      history.push('/negotiate/partner/debts');
    } catch(e) {
      setError(true);
      if (cpfFormatted.length !== 11) {
        setHelperText("Favor digitar todos os dígitos do CPF e tentar novamente");
      } else {
        setHelperText("CPF não confere.");
      }
    }
  };

  const navigatePage = (locate) => {
    const path = {
      pathname: `/${locate}`,
    };
    history.push(path);
  };

  return (
    <Container>    
      <Box color="white">
      {inCooldown && (
        <Popup>
          <span>
            Por favor, aguardar 30 segundos antes de tentar novamente.
          </span>
          <Button
            text="Sair"
            color="secondary"
            variant="contained"
            maxWidth="300px"
            size="large"
            onClick={() => toggleCooldown(false)}
            marginTop="20px"
          />
        </Popup>
      )}
      <Backdrop open={loading} />
      <Paper
        elevation={5}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "60px",
        }}
      >
       
        <Title text={`Obrigado,`} 
          paragraph={true} align='left'
        />
        <Paragraph
          text={`
          Temos uma <b>EXCELENTE PROPOSTA</b> para você negociar sua dívida oriunda do <b>${client.creditorName}</b> com até <b>90% de DESCONTO!</b>
          </br>
          </br>
          Para conferir as proposta, <b>precisamos que nos informe o seu CPF.</b></br>Esse dado é necessário para que confirmemos seus dados bancários e não exista o risco de repassar informações a pessoas indevidas. 
          </br>
          </br>
          Mas pode ficar tranquilo, usaremos o seu CPF apenas para consultar o sistema. 
          `}
          align='left'
          paragraph
        />

        <Tooltip
          className="tooltip"
          text={'Por que devo confirmar meu CPF?'}
          legend="Por se tratar de informações confidenciais, é necessário uma confirmação bancária a fim de que não repassemos informações a pessoas indevidas. Por segurança, informamos alguns dígitos do seu CPF e pedimos a confirmação apenas dos 3 dígitos marcados com X"
        />
        
        <TextField
          className="textarea"
          error={error}
          helperText={helperText}
          onChange={(e) => onChangeCPFValue(e.target.value)}
          value={cpf}
          size="small"
          inputProps={{
            type: "tel",
            style: { fontSize: 20},
            maxLength: 14,
          }}
          placeholder="Informe o seu CPF"
        />

        {/* <span
          style={{
            marginTop: "10px",
            fontSize: "14px",
            textAlign:  "left",
          }}
        >
          Ao clicar em "Confirmar meu CPF" você sinaliza que concorda com os Termos de Uso e Politica de Privacidade da plataforma iQuitei
        </span> */}

        <Button
          text="CONFIRMAR MEU CPF"
          color="primary"
          variant="contained"
          onClick={confirmateCpf}
          size="large"
          marginTop={16}
          align= 'center'
        />
      </Paper>

 
      <a className="about" onClick={() => navigatePage("about")}>
        <Title text={`Sobre a ${client?.partnerName}`} align="center" margin="20px 0"/>
      </a>

      {/* <a className="about" onClick={() => navigatePage("about")}>
        <Title text={`Quem é a iQuitei?`} align="center" margin="20px 0"/>
      </a> */}

    </Box>
    </Container>  
  );
}
