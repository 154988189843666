import { makeStyles } from "@mui/styles";
import { Typography, Box } from "@mui/material";
import Timer from "../../utils/timer";
import theme from '../../theme';

const useStyles = makeStyles((theme) => ({
    box: {
        width: '30%',
        minWidth: '200px',
        padding: '16px 50px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '6px',
        margin: '10px'
    }
}))



export default function TimerBox(props) {
    const creditor = props.creditor;
    const classes = useStyles()
    const date = new Date();

    const hour = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return (
        <Box className={classes.box} style={{ background: (creditor && theme.creditor[creditor]) ? theme.creditor[creditor].destaque : '#FF426C', color: 'white', fontSize: '25px', fontWeight: "bold", fontFamily:props.fontFamily }}>
            <Typography style={{ fontWeight: 300, color: 'white', fontSize: '16px', marginBottom: '10px', fontFamily:props.fontFamily }}>
                ESSA OFERTA EXPIRA EM:
            </Typography>

            <Timer
                initialHour={24 - hour}
                initialMinute={60 - minutes}
                initialSeconds={60 - seconds}
            />
            
        </Box>
    )
}