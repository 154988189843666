import styled from "styled-components";
import theme from "../../../theme";

export const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;

    .clientName {
        display: flex;

        font-family: 'dosis-semibold', ;
        font-size: ${theme.text.size.xl};
    }

    .tooltip {
        align-self: flex-end;
    }

    input {
        text-align: center;
        width: 100%;
    }
    input::placeholder {
        text-align: center;
    }

    .about {
        background: transparent;
        border: none;
        color: ${theme.colors.primary};
        margin: 20px auto;
        text-align: center;
        cursor: pointer;
    }

`;