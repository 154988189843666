/* eslint-disable import/no-anonymous-default-export */
import ConfirmateCpf from './ConfirmateCPF';
import Debts from './Debts';
import Proposal from './Proposal';
import ConfirmateProposal from './ConfirmateProposal';
import SubmitNegotiation from './SubmitNegotiation';
import SubmitClientInfo from './SubmitClientInfo';
import Home from './Home';


export default {
  Home,
  ConfirmateCpf,
  Debts,
  Proposal,
  ConfirmateProposal,
  SubmitNegotiation,
  SubmitClientInfo,
};
