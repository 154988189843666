import Typography from "@mui/material/Typography";

export default function Title(props) {
    return (
         <Typography 
         variant="h6" 
         fontSize={props.fontSize} 
         align={props.align ? props.align: "center"} 
         paragraph={props.paragraph} 
         fontWeight={props.fontWeight ? props.fontWeight : "600"}
         color={props.color}
         margin= {props.margin ? props.margin: '20'} 
         className={props.className}
         fontFamily={props.fontFamily}
         >
             {props.text}            
         </Typography>
        // <h2>
        //      {props.text}   
        // </h2>
    )
}