import React, { useContext } from 'react'
import MyContainer from '../../../components/Box'
import Paragraph from '../../../components/Paragraph'
import Title from '../../../components/Title'
import ImageError from '../../../assets/images/error-image.svg';
import { Paper } from '@mui/material'

import { ClientContext } from '../../../context/client';
import { Button } from '../../../components'


function ExpiredLink() {
    const { client } = useContext(ClientContext);

    return (
        
        <MyContainer style={{maxWidth: '600px'}}>
            <img  src={ImageError} alt="imagem de erro" style={{
                margin: '0 auto',
                width: '30%',
            }}/>
            <br/>

            <Paper
                elevation={3}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '16px',
                    marginBottom: '40px'
                }}
            >
                <Title text="Ops!" fontFamily="Dosis-semibold"/>
                <div style={{ padding: '32px 0' }}>
                    <Paragraph
                        text={`Infelizmente a proposta que você tentou acessar não está mais disponível.`}
                        className="font-dossis-medium"
                    />
                    <Paragraph
                        text= {`Isso acontece por que as propostas tem uma data de validade.`}
                        paragraph
                        className="font-dossis-medium"
                    />

                    <Paragraph
                        text= {`Para acessar uma proposta atualizada, <b>entre em contato com a ${client.creditorName}</b> pelo telefone:`}
                        paragraph
                        className="font-dossis-medium"
                    />

                    <Title
                        text={client.partnerPhone || '0800-000-0000'}
                        paragraph
                        fontFamily="Dosis-semibold"
                    />

                    <Paragraph
                        text= {`Ou se preferir, <b>acessar o site da ${client.creditorName}</b> para mais informações`}
                        paragraph
                        className="font-dossis-medium"
                    />
                </div>
                <Button
                    text={`Ir para o site da ${client.creditorName}`}
                    color="primary"
                    variant="contained"
                    maxWidth="100%"
                    size="large"
                    onClick={() => window.location.href(client.creditorURL || 'https://iquitei.com.br')}
                    className="font-dossis-medium"
                    creditor={client?.creditorId}
                />
            </Paper>
        </MyContainer>
 
    )
}

export default ExpiredLink