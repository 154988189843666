import axios from 'axios';

const API_IQUITEI_NEGOTIATIONS_URL = `${process.env.REACT_APP_NEGOTIATION_API_URL}`;

const getHeaders = () => {
  const headers = {
    'Content-Type': 'application/json',
  };

  return headers;
};

export const LINK = axios.create({
  baseURL: `${API_IQUITEI_NEGOTIATIONS_URL}`,
  headers: getHeaders(),
});

export const getRequest = (url, config = {}) => {
  return LINK.get(url, {
    ...config,
  });
};

export const postRequest = (url, body, config = {}) => {
  return LINK.post(url, body, {
    ...config,
  });
};

// export const postMultipartRequest = (url, body, config = {}) => {
//   const options = {
//
//     ...config,
//   };

//   options.headers['Content-Type'] = 'multipart/form-data';

//   return LINK.post(url, body, options);
// };

export const putRequest = (url, body, config = {}) => {
  return LINK.put(url, body, {
    ...config,
  });
};

export const deleteRequest = (url, config = {}) => {
  return LINK.delete(url, {
    ...config,
  });
};
