export default function formatMessage (message, client) {
  const regexp = /{[A-Za-z0-9_]+}/g;

  if (!message) return 'Vazio';


    let found = message.match(regexp);
    let formatedMessage = message;
    if (found) {
      let clHeaders = JSON.parse(client?.clHeaders || '[]');
      let vars = client?.vars;
      if(vars){
        vars = JSON.parse(vars)
        // eslint-disable-next-line
        found.map((item) => {
          let filterItem = item.replace("{", "").replace("}", "");

          if (filterItem === "Nome")
            return (formatedMessage = formatedMessage.replace(
              item,
              client?.clientName
            ));
          if (filterItem === "CPF_CNPJ")
            return (formatedMessage = formatedMessage.replace(
              item,
              client?.cpf
            ));
          if (filterItem === "Telefone")
            return (formatedMessage = formatedMessage.replace(
              item,
              client?.phone
            ));
          let position = clHeaders.indexOf(filterItem);
          
          formatedMessage = formatedMessage.replace(item, vars[position]);
        });
        return formatedMessage;
      }
    }
  return message
};
