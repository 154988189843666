import style from "styled-components";
import theme from "../../theme";

export default style.div`
  align-itens: flex-start;
  display: flex;
  flex-direction: column;
  font-family: Nunito;
  margin: 0 auto;
  width: 80;

  button.primary {
    background-color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].primary : theme.creditor['0'].primary};
  }
  button.secondary {
    color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].primary : theme.creditor['0'].primary};
  }
  input[type="radio"] {
    accent-color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].primary : theme.creditor['0'].primary}!important;
  }

  h2 {
    font-size: 20px;
  }
  div.desc {
    color: ${theme.colors.dark};
    font-size: 18px;
    margin: 36px 0; 
  }
  label {
    align-itens: flex-start;
    display: flex;
    margin: 10px 0;
    text-align: start;
    span {
      font-size: 16px;
    }
    input {
      margin-right: 10px;
    }
  }
  label.reason-other {
    align-itens: flex-start;
    display: flex;
    flex-direction: column;
    span {
      font-size: 14px;
      margin: 3px 5px;
      margin-bottom: 15px;
    }
    textarea {
      border: 1px solid ${theme.border.color};
      border-radius: ${theme.border.radius};
      height: 100px;
      padding: 3px 10px;
    }
  }
  div.space {
    height: 100px;
    width: 100%;
  }
  input[type="radio"] {
    accent-color:${theme.colors.primary};
  }
  div.attention {
    display: flex;
    justify-content: center;
    border-radius: 5px;
    color: white;
    padding: 10px 0;
    h2, h1 {
      margin: 0;
    }
    h2 {
      font-size: 15px;
    }
    h1 {
      font-size: 24px;
    }
  }
  button:disabled {
    opacity: 0.2;
  }
`;
