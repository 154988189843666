/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useContext } from "react";

//Components
import Paragraph from "../../../components/Paragraph";
import TimerBox from "../../../components/TimerBox";
import Button from "../../../components/Button";
import { Paper } from "@mui/material";
import MyBackDrop from '../../../components/Backdrop'

import Title from "../../../components/Title";
import { formatValue } from "../../../utils/value.format";
import { decimal } from "../../../utils/2decimal.format";

import { useLocation, useHistory } from "react-router";

import { ClientContext } from '../../../context/client';

import theme from "../../../theme";
import { iquiteiApi, iquiteiNegotiations } from '../../../services';
import { Container } from "./styled";

function ConfirmateProposal() {
  const { client } = useContext(ClientContext);
  const proposal = useLocation().state.item.proposal;

  const [timer, setTimer] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [mostraParcelas, setMostraParcelas] = useState(false);

  let path = '';

  useEffect(() => {
    const pathString = sessionStorage.getItem('clientPath');
    if (pathString) {
      path = pathString;
    }
  }, []);
  const backProposal = useCallback(() => {
    const location = {
      state: { client },
    };
    history.push(location);
  }, [client, history]);

  useEffect(() => {
    if (timer) {
      backProposal();
    }
  }, [timer, backProposal]);

  const submitNegotiation = async (proposal) => {
    try {
      setLoading(true)

      const [, arrangement] = await Promise.all([
        iquiteiApi.postRequest(
          `/partners/${client.partnerId}/v2/negotiate`,
          {
            id: client.clientId,
            portionAmount: proposal.DownPaymentAmount,
            portion: proposal.TotalInstallments,
            amount: proposal.DownPaymentAmount * proposal.TotalInstallments,
            debt: proposal.DebtTotalBalance,
            negotiationExpiration: proposal.lastDueDate,
            dealId: proposal.NegotiationID || 0,
          }
        ),
        iquiteiNegotiations.postRequest(
          `/partners/${client.partnerId}/submitArrangement/${client.creditorId}`,
          {
            client: client,
            proposal: proposal,
          }
        ),
      ]);
      
      const ticket = await iquiteiNegotiations.postRequest(
          `/partners/${client.partnerId}/ticketArrangement/${client.creditorId}`,
          {
            client: client,
            data: arrangement.data.data,
            proposal: proposal,
          }
        );
      const url = "/submitNegotiation";
      const location = {
        pathname: url,
        state: {
          proposal: proposal,
          ticket: ticket.data,
        },
      };

      history.push(location);
      setLoading(false);
    } catch (error) {
      const location = {
        pathname: "/404",
        state: {
          lastState: "/",
          description: "Erro ao buscar cliente pelo ID",
          myThrow: error.message,
          status: error.response?.status,
          payload: error.request?.responseURL,
        },
      };
      history.push(location);
      setLoading(false);
    }
  };

  return (
    <Container creditor={client?.creditorId}>
      <MyBackDrop open={loading} />

      <Paper
        elevation={3}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "40px",
          margin: '0 10px'
        }}
      >
        <div style={{ alignSelf: "flex-start" }}>
          <Title
            align="left"
            text={client.clientName + ","}
            paragraph
            fontFamily='Dosis-semibold'
          />

          <Paragraph
            align="left"
            text="Com o pagamento da parcela, o credor providenciará a exclusão do nome nos Órgãos de Proteção ao Crédito com relação ao saldo deste contrato em caso de constar registro."
            paragraph
            className="font-dossis-medium"
          />
        </div>

        <Paragraph 
        text="<b>Conforme selecionado, assim ficou sua proposta:</b>"  paragraph
        className="font-dossis-medium"/>

        <Paragraph
          text={`Valor atual${Number(proposal.DiscountRate) > 0 ? ' com desconto:' : ':'} ${Number(client?.creditorId) === 6 ? formatValue(Number(proposal.DebtTotalBalance) - Number(proposal.DiscountRate)) : formatValue(proposal.TotalInstallments * proposal.DownPaymentAmount)}`}
          fontSize="18px"
          color={client?.creditorId && theme.creditor[client?.creditorId] ? theme.creditor[client?.creditorId].secondary : theme.colors.lightblue}
          className="font-dossis-medium"
        />

        <div style={{ display: 'flex', alignItems: 'center', position: 'relative', flexDirection: Number(client?.creditorId) === 6 ? 'column' : 'row', width: '100%' }}>
          <Paragraph
            text={`Pagamento em `}
            fontSize="16px"
            className="font-dossis-medium"
          />
          {Number(client?.creditorId) === 6 ? 
          <div style={{ width: '100%', height: mostraParcelas ? 'unset' : '100px', position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between', overflowY: 'hidden' }}>
          {proposal?.parcelasParcelamento.map((parc) => {
              return <Title fontFamily='Dosis-medium' text={`${parc.numeroParcela}ª de ${formatValue(parc.valorPrincipal)}` } />
          })}
          <button
              onClick={() => setMostraParcelas(!mostraParcelas)}
              style={{
                  position: 'absolute',
                  right: '0',
                  top: '0',
                  border: '1px solid gray',
                  borderRadius: '10px',
                  padding: '10px 20px',
                  background: 'transparent',
                  color: 'blue',
                  fontWeight: 'bold'
              }}>{mostraParcelas ? 'Esconder' : 'Ver mais'}</button>
          </div>
          : <Title
            text={` ${proposal.TotalInstallments}x de ${formatValue(
              proposal.DownPaymentAmount
            )}`}
            fontSize="22px"
            margin="0 5px"
            fontFamily="Dosis-medium"
          />}
        </div>

        <Paragraph
          text={`Vencimento em ${proposal.lastDueDate}`}
          fontSize="16px"
          className="font-dossis-medium"
        />
        {Number(client?.creditorId) !== 6 &&
        <>
          <Paragraph
            text={`Desconto especial de ${decimal(proposal.DiscountRate, 2)}%`}
            color={client?.creditorId && theme.creditor[client?.creditorId] ? theme.creditor[client?.creditorId].primary : theme.colors.blue}
            fontSize="16px"
            className="font-dossis-medium"
          />

          <Paragraph
            text={`Você economizará  ${formatValue(
              proposal.DebtTotalBalance -
                proposal.DownPaymentAmount * proposal.TotalInstallments
            )}`}
            color={theme.colors.green}
            fontSize="16px"
            className="font-dossis-medium"
          />
        </>}

        <TimerBox creditor={client?.creditorId} timer={timer} setTimer={setTimer} fontFamily={'Dosis-Medium'}/>

        <Button
          text="CONFIRMAR NEGOCIAÇÃO"
          color="primary"
          variant="contained"
          size="large"
          marginTop="32px"
          onClick={() => submitNegotiation(proposal)}
          fontFamily= 'Dosis-medium'
        />
      
      </Paper>
      <div style={{ textAlign:'center' }}>
        <Button
          text="VOLTAR"
          color="transparent"
          marginTop="20px"
          size="large"
          onClick={(event) => history.push(path)}
          fontFamily= 'Dosis-semibold'
        />
      </div>
    </Container>
  );
}

export default ConfirmateProposal;
