import { useHistory } from "react-router-dom";

import { Container } from './style';

import {ReactComponent as ReactLogo} from '../../../../../assets/icons/doblequote.svg';


const depositionsCard = [
  {
    name: 'Karina Santos',
    description: 'Foi muito bom, resolvi minha situação com poucos cliques e sai com meu boleto em mãos já' 
  },
  {
    name: 'Carlos Pereira',
    description: 'Muito melhor do que ligações incomodas. Consegui acessar a proposta, revisar com calma e depois de conversar com minha esposa, optei pelo plano que se encaixa na nossa situação e realizei o acordo'
  },
  {
    name: 'José Lima',
    description: 'Recebi a mensagem mas não tinha condições de negociar, dias depois consegui pelo mesmo canal encontrar uma proposta em 9x que dava no meu orçamento e negociei'
  },
  {
    name: 'Priscila Silva',
    description: 'Fui atendida rapidamente pela empresa e consegui fazer um acordo',
  }
];

export default function DepositionsHome({ url, creditor }) {
  const history = useHistory();

  return (
    <Container creditor={creditor} className="depositions">
      <div className="container">
        <span className="depositions-title font-dossis-bold">
          DEPOIMENTOS DE QUEM JÁ
        </span>
        <br/>
        <span className="depositions-title font-dossis-bold destaque">
          NEGOCIOU COM A IQUITEI
        </span>

        <div className="deposition-list">
          {depositionsCard.map((i, index) => (
            <div className="deposition-card" key={index}>
              <ReactLogo />
              <span className="deposition-description font-dossis-semibold">
                {i.description}
              </span>
              <span className="deposition-name font-dossis-mediumå">
                {i.name}
              </span>
            </div>
          ))}
        </div>
        
        <div className="button-container">
          <button
          style={{
            width: '50%'
          }}
            className="button-negotiate font-dossis-semibold"
            onClick={() =>
              history.push(url)
            }
          >
            QUERO NEGOCIAR COM DESCONTO!
          </button>
        </div>
      </div>
    </Container>
  );
};
