export default function phoneFormat(phone) {
  if (!phone) return '0800 000 000';

  const phoneSplit = phone.toString().trim().split('');

  if (phone.toString().trim().startsWith('08')) {
    let newPhone = '';
    for (let index = 0; index < phoneSplit.length; index += 1) {
      if (index === 4 || index === 7) newPhone += ` ${phoneSplit[index]}`;
      else newPhone += phoneSplit[index];
    }
    return newPhone;
  }

  if (phoneSplit.length === 10) {
    return '(' + phone.slice(0, 2) + ') ' + phone.slice(2, 6) + ' ' + phone.slice(6);
  }
  if (phoneSplit.length === 11) {
    return '(' + phone.slice(0, 2) + ') ' + phone.slice(2, 6) + ' ' + phone.slice(6);
  }
  return phone;
};