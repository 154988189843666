import { useState, useEffect } from 'react';

const Timer = (props) => {
    const {initialHour = 0, initialMinute = 0, initialSeconds = 0} = props;
    const [hours, setHours] = useState(initialHour);
    const [ minutes, setMinutes ] = useState(initialMinute);
    const [seconds, setSeconds ] =  useState(initialSeconds);
    useEffect(()=>{
    let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes > 0) {
                  setMinutes(minutes - 1);
                  setSeconds(59);
                } else {
                  if (minutes === 0 && hours === 0) {
                    clearInterval(myInterval)
                  } else {
                    setHours(hours - 1);
                    setMinutes(59);
                  }
                }
            } 
        }, 1000)
        return () => {
            clearInterval(myInterval);
          };
    });

    const numberConvert = (number) => {
      if (number === 0) return `00`;
      if (number.toString().length === 1) return `0${number}`;

      return number;
    };

    return (
      <span>{numberConvert(hours)}:{numberConvert(minutes)} minutos</span>
    )
}

export default Timer;
