/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../../components/Button'
import Paragraph from '../../../components/Paragraph'
import Title from '../../../components/Title'
import theme from '../../../theme'


function NotFound() {
    const history = useHistory();
    let path = '';

    useEffect(() => {
        const pathString = sessionStorage.getItem('clientPath');

        if(pathString) {
            path = pathString;
        }
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Title text="404" fontSize={100} color={theme.colors.red} fontFamily='Dosis-bold'/>
            <Title text="Página não encontrada" fontFamily='Dosis-bold'/>
            <div style={{ paddingTop: '32px' }}>
                <Paragraph text="O link em que você clicou pode estar quebrado ou a página pode ter sido removida ou renomeada" className='font-dossis-medium'/>
            </div>
            <Button
                text="Voltar"
                color="primary"
                maxWidth="360px"
                marginTop="16px"
                onClick={() => history.push(path)}
                fontFamily= 'Dosis-semibold'
            />
        </div>
    )
}

export default NotFound