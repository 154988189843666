import { Container } from './style';

import {ReactComponent as ReactLogo} from '../../../../../assets/images/about-iquitei-image.svg';

export default function AboutIQuiteiHome({ creditor }) {
  return (
    <Container creditor={creditor}>
      <div className='container'>
        <span className="title font-dossis-bold">SOBRE <span className="destaque">A IQUITEI</span></span>

        <div className="content">
          {/* <img src={Image} alt="desenho de monitor, celular e tablet"/> */}
          <ReactLogo/>

          <span className="about font-dossis-medium">
          <p>
          Somos uma empresa de atendimento e negociação digital.
          Temos parceria com diversas assessorias e empresas que utilizam nossos canais para trazer mais comodidade e transparência aos seus clientes, portanto, todas as propostas apresentadas aqui, são validas e disponibilizadas pelos bancos.
          </p>
          </span>
        </div>
      </div>
    </Container>
  );
};
