import styled from "styled-components";
// import theme from "../../../theme";

export const Container = styled.div`
    display: flex;
    minHeight: 100vh;
    height: 100vh;


    header {
        align-items: center;
        display: flex;
        justify-content: space-around;
        width: 90%;

        img {
            width: 30%;
        }
    }

    .creditor-partner {
        align-items: center;
        display: flex;
        justify-content: space-around;
        margin: 0 auto;
        padding: 5px 0;
        width: 100%;
    
        img {
          max-width: 30%;
    
          @media only screen and (min-width: 900px) {
            max-width: 16%;
          }
        }
      }

    .image-side {
        display: none;


        .creditor-image {
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 2;
        }

        .background {
          background-color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].secondary : theme.creditor['0'].primary};
          opacity: 0.4;
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;
        }
    }

.div1{
    alignItems: center;
    display: flex;
    flexDirection: column;
    justifyContent: space-between;
    height: 100%;
    width: 100%;
}
BoxName{
    background-color: red;
}

@media only screen and (min-width: 900px) {
    .image-side {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
    }
  }
`;
