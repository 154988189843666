import { Paper } from "@mui/material";
import MyContainer from "../../../components/Box";
import Button from "../../../components/Button";
import Paragraph from "../../../components/Paragraph";
import Title from "../../../components/Title";
import theme from "../../../theme";

// import { useHistory } from 'react-router';

function CpfNotFound() {
  // const history = useHistory();

  // useEffect(() => {
  //     const data = JSON.stringify({
  //         "origin": "IQUITEI",
  //         "lastState": history.location.state.lastState || 'Empty',
  //         "description": history.location.state.description || 'Empty',
  //         "myThrow": `${history.location.state.myThrow}` || 'Empty',
  //         "status": history.location.state.status || 400,
  //         "payload": history.location.state.payload || 'Empty',
  //       });

  //     iquiteiApi.postRequest('/partners/1/error', data)
  //       .then(function (response) {
  //         console.log(JSON.stringify(response.data));
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  // }, [history])

  return (
    <MyContainer>
      <Paper  elevation={3}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "15px",
          justifyContent: "center"
        }}>
      <Title
        fontFamily='Dosis-bold'
        text="Cliente não encontrado"
        fontSize={20}
        color={theme.colors.blue}
        style={{ marginBottom: "32px" }}
      />
      <div style={{ paddingTop: "32px" }}>
        <Title
          fontSize={16}
          text="Seu débito não está mais vinculado à essa assessoria."
          fontFamily='Dosis-bold'
        />
      </div>

      <div style={{ paddingTop: "32px", marginBottom: '20px' }}>
      <Paragraph text="Por favor entre em contato com a Recovery ligando para <b>0800-779-8899</b> ou clique em um dos botões abaixo:" className='font-dossis-medium' />
      </div>
      <div style={{display:'contents'}}>
      <Button
        text="Ir para o site Recovery"
        color="primary"
        maxWidth="360px"
        margin="20px auto"
        fontFamily='Dosis-medium'
        onClick={() => {
          window.location.href = "https://www.gruporecovery.com/";
        }}
      />

      <Button
        text="Falar com a Recovery pelo Whatsapp"
        color="primary"
        maxWidth="360px"
        margin="20px auto"
        fontFamily='Dosis-medium'
        onClick={() => {
          window.location.href = "https://api.whatsapp.com/send?phone=551147658402&text=Ol%C3%A1!%20Eu%20gostaria%20de%20obter%20mais%20informa%C3%A7%C3%B5es%20sobre%20meu%20d%C3%A9bito";
        }}
      />

      </div>
      </Paper>
    </MyContainer>
  );
}

export default CpfNotFound;
