import styled from "styled-components";
import theme from "../../theme";

const Popup = styled.div`
  background-color: ${theme.colors.loading.background};
  color: ${theme.colors.primary};
  position: fixed;
  font-size: ${theme.text.size.xl};
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 2000;
  display: flex;
  justify-content: center;

  div.children {
    align-items: center;
    background-color: ${theme.colors.light};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
    position: fixed;
    top: 25%;
    left: 8%;
    width: 80%;
  }
  @media screen and (min-width: 1000px) {
    div.children {
      left: 31.5%;
      width: 35%;
    }
  }
`;

export default function PopupContainer({ children }) {
  return (
    <Popup>
      <div className="children">{children}</div>
    </Popup>
  );
}
