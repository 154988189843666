export const decimal = (value, decimalCase) => {
    value = parseFloat(value)
    if(value){
        return value.toFixed(decimalCase)
    }

      
  };
  

  