import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  width: 70%;

  margin-top: 94px;

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;

    text-transform: uppercase;

    color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].third : theme.creditor['0'].primary};
    .destaque {
      color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].third : theme.creditor['0'].third};
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 51px;

    @media screen and (max-width: 1450px) {
      flex-direction: column;
      align-items: center;;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      text-align: justify;
    }

    .about {
      margin-right: 98px;

      @media screen and (max-width: 1450px) {
        margin: 40px;
      }
    }
  }

  svg {
    fill: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].primary : theme.creditor['0'].primary};
    width: 100%;
  }

  @media only screen and (min-width: 900px) {
    svg {
      width: 50%;
    }
  }
`;
