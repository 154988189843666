import styled from "styled-components";
// import theme from "../../../theme";

export const Container = styled.div`
    margin: 20px auto;

    .list-items {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 18px;

        @media only screen and (min-width: 900px) {
            flex-direction: row;
        }
    }
`;