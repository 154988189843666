import React, { useContext, useEffect, useState } from "react";

import Title from "../../../components/Title";
import Paragraph from "../../../components/Paragraph";

import { Paper } from "@mui/material";
import { formatValue } from "../../../utils/value.format";

import { useLocation } from "react-router";
import Button from "../../../components/Button";
import theme from "../../../theme";
import { ClientContext } from "../../../context/client";
import { iquiteiApi } from "../../../services";

import Feedback from "../../../components/Feedback";

function SubmitNegotiation() {
  const { client } = useContext(ClientContext);

  const [showFeedback, setShowFeedback] = useState(true);

  const ticket = useLocation().state.ticket.data
  const proposal = useLocation().state.proposal;

  useEffect(() => {
    (async () => {
      await iquiteiApi.putRequest(
        `/partners/${client.partnerId}/v2/site/client/${client.clientId}`,
        {
          step: 'TICKET_DOWNLOAD',
        },
      );
    })()
  }, [client]);

  const downloadTicket = () =>{
    window.open(ticket.link)
  }

  return (
    <div style={{ flex: '1', margin: '0 10px' }}>
      <Paper
        elevation={3}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          marginBottom: "10px",
          wordWrap: "break-word",
          marginTop: '20px'

        }}
      >
        <Feedback 
          showFeedback={showFeedback}
          setShowFeedback={setShowFeedback}
          clientId={client?.clientId}
          partnerId={client?.partnerId}
        />

        <Title
          text={`🎉Parabéns, ${client?.clientName}🎉`}
          paragraph={true}
          fontWeight={500}
          fontSize="25px"
        />
        <Paragraph
          text={`Você deu o primeiro passo para sua liberdade financeira.`}
          paragraph={true}
          fontSize="18px"
        />

        <Paragraph
          text={`O seu acordo foi gravado na ${client.creditorName}. Com o pagamento da primeira parcela, o credor providenciará a exclusão do nome nos Órgãos de Proteção ao Crédito com relação ao saldo deste contrato em caso de constar registro.`}
          paragraph={true}
          fontSize="18px"
        />
        <div style={{width: '100%'}}> {/* Feito para casos com o digitableLine sem espaços, o texto precisava de uma div com tamanho ao redor para quebrar linha */}
          <Title
            text={`Seu acordo:`}
            paragraph={true}
            fontSize="18px"
          />

          <Paragraph
            text={`Valor total do seu acordo: ${formatValue(proposal.TotalInstallments * proposal.DownPaymentAmount)}`}
            fontSize="16px"
            color={theme.colors.lightblue}
          />

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <Paragraph
              text={`Pagamento em `}
              fontSize="18px"
            />
            <Title
              text={` ${proposal.TotalInstallments}x de ${formatValue(
                proposal.DownPaymentAmount
              )}`}
              fontSize="25px"
              margin="5px"
            />
          </div>

          <Paragraph
            text={`Vencimento em ${proposal.lastDueDate}`}
            fontSize="18px"
            paragraph
          />
          
          <Paragraph
            text={`Linha digitável:`}
            fontSize="18px"
          />
          <Paragraph
            text={`<b>${ticket.digitableLine}</b>`}
            paragraph={true}
            fontSize="18px"
          />
        </div>

        <Button
          text="COPIAR LINHA DIGITÁVEL"
          color="primary"
          variant="contained"
          onClick={() => navigator.clipboard.writeText(ticket.digitableLine)}
        />
        <Button
          text="Baixar boleto da primeira parcela"
          color="primary"
          variant="contained"
          icon="PDF"
          onClick={() => downloadTicket()}
        />
        
      </Paper>
    </div>
  );
}

export default SubmitNegotiation;
