import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


const ComponentBox = styled(Box)((props) => ({
    backgroundColor: props.bgColor,
    display: 'flex',
    flexDirection: 'column',
    alignItems: props.align,
    width: '100%',

}));


export default function BoxName(props) {

    return (
        <ComponentBox
            align={props.align}
            onClick={(event) => {
                window.location.href = props.site
            }}
            style={{
                cursor: 'pointer',
                margin: 30,
                fontSize: 20,
            }}
        >

            <Typography style={{ fontWeight: 600 }}>
                {props.name?.toUpperCase()}
            </Typography>

        </ComponentBox>

    )
}

