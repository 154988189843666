import React, { useState } from 'react';
import { Button, ButtonGroup } from '@mui/material';



import theme from '../../theme';


export default function BasicButtonGroup({ typeNegotiate, setTypeNegotiate, maxWidth, creditor }) {

  let disabled = {
    borderRadius: "8px",
    backgroundColor: theme.colors.light,
    color: (creditor && theme.creditor[creditor]) ? theme.creditor[creditor].primary : theme.colors.primary,
    borderColor: (creditor && theme.creditor[creditor]) ? theme.creditor[creditor].primary : theme.colors.primary,
    fontSize: "18px !important",
    fontWeight: "bold",
    padding: "16px 50px",
    fontFamily:'Dosis-bold',
    '&:hover': {
      backgroundColor: theme.colors.light,
      color: (creditor && theme.creditor[creditor]) ? theme.creditor[creditor].primary : theme.colors.primary,
      borderColor: (creditor && theme.creditor[creditor]) ? theme.creditor[creditor].primary : theme.colors.primary,
    }
  }

  let active = {
    borderRadius: "8px",
    backgroundColor: (creditor && theme.creditor[creditor]) ? theme.creditor[creditor].primary : theme.colors.primary,
    color: theme.colors.light,
    borderColor: (creditor && theme.creditor[creditor]) ? theme.creditor[creditor].primary : theme.colors.primary,
    fontSize: "18px !important",
    fontWeight: "bold",
    padding: "16px 50px",
    fontFamily:'Dosis-bold',
    '&:hover': {
      backgroundColor: (creditor && theme.creditor[creditor]) ? theme.creditor[creditor].primary : theme.colors.primary,
      color: theme.colors.light,
      borderColor: (creditor && theme.creditor[creditor]) ? theme.creditor[creditor].primary : theme.colors.primary,
    }

  }

  const [variant1, setVariant1] = useState(active)
  const [variant2, setVariant2] = useState(disabled)
  const buttonClick = (event) => {
    switch (event.target.id) {
      case '1':
        setVariant1(active)
        setVariant2(disabled)
        setTypeNegotiate(1)
        break;
      case '2':
        setVariant2(active)
        setVariant1(disabled)
        setTypeNegotiate(2)
        break;
      default:
        break;
    }
  }


  return (
    <div style={{ width: "100%", display: 'flex', justifyContent: 'center', maxWidth }}>
      <ButtonGroup
        aria-label="large button group"
        size="large"
        fullWidth="100%"
      >
        {Number(creditor) !== 6 && <Button
          sx={variant1}
          id='1'
          onClick={(event) => buttonClick(event)}>
          <span style={{ fontSize: '15px' }}>VALOR</span>
        </Button>}

        <Button sx={variant2}
          id='2'
          onClick={(event) => buttonClick(event)}
        >
          <span style={{ fontSize: '15px' }}>QUANTIDADE</span>
        </Button>
      </ButtonGroup>
    </div>
  );
}