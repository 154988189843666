/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react'

import Title from '../../components/Title'
import Paragraph from '../../components/Paragraph'
import Button from '../../components/Button'

// import { TextField } from '@mui/material'

import { useHistory } from 'react-router'
// import NumberFormat from 'react-number-format'

import phoneIcon from "../../assets/icons/phone.svg";
import whatsappIcon from "../../assets/icons/whatsapp.svg";

// import { iquiteiApi } from '../../services';

import phoneFormat from '../../utils/phone.format';

import { ClientContext } from '../../context/client';

function CallMe() {
    const { client } = useContext(ClientContext);
    const history = useHistory()
    let path = '/';
 
    useEffect(() => {
      const pathString = sessionStorage.getItem('clientPath');
      if (pathString) {
        path = pathString;
      }
    }, []);

    // const insertCallRequest = async () => {
    //     try {
    //       await iquiteiApi.postRequest('/partners/1/v2/call',{
    //           id: client.clientId
    //       });
    //     } catch (error) {
    //       throw (error)
    //     }
    //   }

      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px auto' }}>
          <Title text={`Olá, ${client?.clientName}`} paragraph={true} />
          {/* <Paragraph text={`Entraremos em contato assim que possível, lembrando que o nosso horário de atendimento é seg a sexta de 08h às 20h e sábado de 08h às 14h`} paragraph={true} />
                <Paragraph text={`O telefone que temos em nossa base de dados é:`} paragraph={true} />
                <NumberFormat customInput={TextField}
                    variant="outlined"
                    format="+55 (##) #####-####" mask="_"
                    autoComplete="off"
                    defaultValue={client.phone}
                    style={{ marginBottom: '16px' }}
                />
    
                <Button
                    text="ME LIGUE"
                    color="primary"
                    variant="contained"
                    maxWidth="300px"
                    size="large"
                    onClick={(event)=> insertCallRequest()}
                /> */}
    
          {client.partnerPhone && (
            <>
              <Paragraph
                text={`Em caso de dúvidas relacionadas ao seu débito, clique em um dos botões abaixo e entre em contato com a central de atendimento do seu credor:`}
                paragraph={true}
              />
              <a
                href={`tel:${client.partnerPhone}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid lightgray",
                  borderRadius: "10px",
                  padding: "10px 20px",
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <img
                  style={{ margin: "0 10px", width: "30px" }}
                  src={phoneIcon}
                  alt="icone de telefone"
                />
                {phoneFormat(client.partnerPhone)}
              </a>
            </>
          )}
          {client.partnerWhatsapp && (
            <>
              <br />
              <Paragraph text={`Ou whatsapp:`} paragraph={true} />
              <a
                href={`https://api.whatsapp.com/send?phone=55${client.partnerWhatsapp}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid lightgray",
                  borderRadius: "10px",
                  padding: "10px 20px",
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <img
                  style={{ margin: "0 10px", width: "30px" }}
                  src={whatsappIcon}
                  alt="icone do whatsapp"
                />
                {phoneFormat(client.partnerWhatsapp)}
              </a>
            </>
          )}
    
          <Button
            text="VOLTAR"
            color="transparent"
            maxWidth="300px"
            marginTop="40px"
            size="large"
            onClick={(event) => history.push(path)}
          />
        </div>
    )
}

export default CallMe;
