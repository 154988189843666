import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";

import iquiteiLogo from "../../../assets/images/iquitei-logo.svg";
import { Container, Loading, LoadingImg } from "./styled";
import { iquiteiApi } from "../../../services";
import { ClientContext } from "../../../context/client";
import { useLocation } from "react-router-dom";
import formatMessage from '../../../utils/formatMessage';
import phoneFormat from '../../../utils/phone.format'

// import '../../../fonts/Dosis-Medium.ttf'
// import '../../../fonts/Dosis-SemiBold.ttf'
// import '../../../fonts/Dosis-Bold.ttf'

import { 
  AboutPartner,
  AboutIQuitei,
  Depositions,
  Reasons,
} from './components';

function HomeNegotiation() {
  let history = useHistory();
  let location = useLocation();
  const { setClient, client } = useContext(ClientContext);

  const pageValidatePath = '/validate';

  const [loading, setLoading] = useState(true);

  const getClientById = async (id) => {
    try {
      const response = await iquiteiApi.getRequest(
        `/partners/1/v2/client${id}`
      );
      const clientData = response.data.data[0]
      const config = {
        id: clientData.clientId,
        document: clientData.cpf,
      };

      setClient(clientData);
      
      await iquiteiApi.postRequest(
        `/partners/${clientData?.partnerId}/v2/queue/negotiation?type=onlyAccess`,
        config
      );
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    sessionStorage.setItem('clientPath', location.pathname);
    (async () => {
      await Promise.all([
        getClientById(location.pathname),
      ]).finally(() => setLoading(false));

      history.push(location);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      {loading && (
        <Loading className="loading">
          <LoadingImg src={iquiteiLogo} alt="iquitei-logo"/>
        </Loading>
      )}
      <Container creditor={client?.creditorId}>
        <div className="creditor-partner">
          {client && client.creditorImage && (
            <img
              src={client.creditorImage}
              alt="logo"
            />
          )}
          <br />
          {client && client.partnerImage && (
            <img
              src={client.partnerImage}
              alt="hapiness"
            />
          )}
        </div>
        <div
          className="headline"
          style={{
            backgroundImage: `url(${client.headlineImage})`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            boxShadow: "inset 0 0 0 1000px rgb(38 15 99 / 60%)",
            padding: "80px 0",
            position: 'relative',
          }}

        >
          {/* <div className="logo" style={{ margin: '20px 0' }}>
            <img src={iquiteiLogo} alt="hapiness" />
          </div> */}

          <div className="background"><br/></div>

          <div>
            <span 
              className="headline-text font-dossis-semibold"
              dangerouslySetInnerHTML={{
                __html: `${formatMessage(
                  client.headlineText ||
                    `Aproveite o desconto e negocie sua dívida com a ${client.partnerName} e dê
            um passo em direção à mudança de vida!`
                )}`,
                  client,
              }}
            />

            <button
              style={{ marginBottom: '20px', width:'30%' }}
              className="button-negotiate font-dossis-semibold"
              onClick={() =>
                {
                  const path = {
                    pathname: pageValidatePath,
                    state: {
                      client: client,
                      locate: location.pathname,
                    },
                  };
                  history.push(path);
                }
              }
            >
            {client.headlineButton ||
              "CLIQUE AQUI PARA NEGOCIAR COM DESCONTO!"}
          </button>
          </div>

          <div><br/></div>
        </div>

        <Reasons creditor={client?.creditorId} url={pageValidatePath}/>

        <Depositions creditor={client?.creditorId} url={pageValidatePath}/>

        <AboutPartner
          partnerName={client.partnerName}
          partnerAbout={client.partnerInfo}
          creditor={client?.creditorId}
        />

        <AboutIQuitei creditor={client?.creditorId} />

        <div className="footer">
          <div className="logo">
            <img src={iquiteiLogo} alt="hapiness" style={{ width: '100px', margin: '0 auto' }}/>
          </div>
              <div
              style={{ width: '60%', textAlign: 'center' }}>
                <div>
                  <span className="footer-text font-dossis-semibold">
                    A iQuitei é uma plataforma que facilita e torna segura a negociação de dívidas entre credores e assessorias de cobrança e não é responsável por nenhum valor, desconto ou condição de pagamento.
                  </span>
                </div>
              <div style={{
                margin: '30px 0',
                
              }}>
                <span className="footer-credor-text font-dossis-semibold">
                Em caso de dúvidas ou caso queira realizar uma reclamação, <b>entre em contato direto com a <br/>{client.partnerName}</b> por meio do número <a href={`tel:${client.partnerPhone}`} style={{ textDecoration: 'none' }}><b>{phoneFormat(client.partnerPhone)}</b></a>
                </span>
              </div>
          </div>
        </div>
        <div className="business-document">
          <span className="business-document-text font-dossis-semibold">
            iQuitei - Um produto da VHG Soluções - 41.320.364/0001-36
          </span>
        </div>
      </Container>
    </>
  );
}

export default HomeNegotiation;
