export const maskCpf = (cpf) => {
    if (!cpf) return cpf;

    cpf = cpf.toString();
    cpf = cpf.trim();
    let dadoInput = cpf
    // let dadoInput = cpf.toString().replace(/[^0-9]/g, '')
    let dadoCorrigido = "";

    //se tiver faltando zeros insiro como if a baixo
    if (dadoInput.length > 11) {
        dadoCorrigido = dadoInput.padStart(14, 0)
    } else {
        dadoCorrigido = dadoInput.padStart(11, 0)
    }

    let tamanho = dadoCorrigido.length;
    if (tamanho === 11) {
        return formataCPF(cpf)
    } else {
        return formataCNPJ(cpf)
    }
};

function formataCPF(cpf){
    //retira os caracteres indesejados...
    cpf = cpf.replace(/[^\d]/g, "");

    //realizar a formatação...
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.xxx.$3-$4");
}

function formataCNPJ(cnpj){
    //retira os caracteres indesejados...
    cnpj = cnpj.replace(/[^\d]/g, "");

    //realizar a formatação...
        return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.xxx.$3/$4-$5");
}

export const documentCovert = (cpf) => {
    if (!cpf) return cpf;

    cpf = cpf.toString();
    cpf = cpf.trim();
    let dadoInput = cpf
    // let dadoInput = cpf.toString().replace(/[^0-9]/g, '')
    let dadoCorrigido = "";

    //se tiver faltando zeros insiro como if a baixo
    if (dadoInput.length > 11) {
        dadoCorrigido = dadoInput.padStart(14, 0)
    } else {
        dadoCorrigido = dadoInput.padStart(11, 0)
    }

    let tamanho = dadoCorrigido.length;
    if (tamanho === 11) {
        return convertCPF(cpf)
    } else {
        return convertCNPJ(cpf)
    }
};

export function convertCPF(cpf){
    //retira os caracteres indesejados...
    cpf = cpf.replace(/[^\d]/g, "");

    //realizar a formatação...
    if (cpf.length > 9) {
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d)/, "$1.$2.$3-$4");
    }
    if (cpf.length > 6) {
        return cpf.replace(/(\d{3})(\d{3})(\d)/, "$1.$2.$3");
    }
    if (cpf.length > 3) {
        return cpf.replace(/(\d{3})(\d)/, "$1.$2");
    }
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

function convertCNPJ(cnpj){
    //retira os caracteres indesejados...
    cnpj = cnpj.replace(/[^\d]/g, "");

    //realizar a formatação...
        return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
}

export function convertTel(cpf){
    //retira os caracteres indesejados...
    cpf = cpf.replace(/[^\d]/g, "");

    //realizar a formatação...
    if (cpf.length > 10) {
        return cpf.replace(/(\d{2})(\d{1})(\d{4})(\d)/, "($1) $2.$3-$4");
    }
    if (cpf.length > 6) {
        return cpf.replace(/(\d{2})(\d{4})(\d)/, "($1) $2-$3");
    }
    if (cpf.length > 2) {
        return cpf.replace(/(\d{2})(\d)/, "($1) $2");
    }
    return cpf.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2.$3-$4");
}