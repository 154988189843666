import { Button as MuiButton } from "@mui/material";
import theme from '../../theme'
import { WhatsApp } from "@mui/icons-material";
import pdfIcon from '../../assets/icons/pdf.svg';

export default function Button(
    {
        text,
        color,
        variant,
        maxWidth,
        icon,
        onClick,
        size,
        marginTop,
        margin = "8px",
        disabled,
        className,
        fontSize = "20px",
        padding = "16px",
        fontFamily,
        creditor
    }) {
    const standartStyle = {
        width: "100%",
        maxWidth: maxWidth ? maxWidth : "",
        borderRadius: '10px',
        border: "none",
        alignItens: "center",
        justifyContent: "center",
        margin,
        fontSize,
        padding,
        className,
        marginTop: marginTop ? marginTop : "",
        fontFamily
    };

    const primaryStyle = {
        ...standartStyle,
        color: theme.colors.light
    }

    const transparentStyle = {
        ...standartStyle,
        background: 'transparent',
        color: creditor && theme.creditor[creditor] ? theme.creditor[creditor].primary : theme.creditor['0'].primaryy,
        textDecoration: 'underline',
    }

    const secondaryStyle = {
        ...standartStyle,
        backgroundColor: theme.colors.light,
        border: 1,
        borderStyle: "solid",
        borderColor: creditor && theme.creditor[creditor] ? theme.creditor[creditor].primary : theme.creditor['0'].primaryy,
        color: creditor && theme.creditor[creditor] ? theme.creditor[creditor].primary : theme.creditor['0'].primaryy
    }

    const whatsappStyle = {
        ...standartStyle,
        background: "#075E54"
    }

    const secondaryFogStyle = {
        ...standartStyle,
        background: theme.colors.lightGrey,
        color: creditor && theme.creditor[creditor] ? theme.creditor[creditor].primary : theme.creditor['0'].primaryy
    }

    let style = null;

    switch (color) {
        case "primary":
            style = primaryStyle;
            break;
        case "secondary":
            style = secondaryStyle;
            break;
        case "whatsapp":
            style = whatsappStyle;
            break;
        case "secondaryFog":
            style = secondaryFogStyle;
            break;
        case "transparent":
            style = transparentStyle;
            break;
        default:
            break;
    }

    const renderIcon = (icon) =>{
        if(icon === 'WhatsApp') {
            return <WhatsApp style={{ marginRight: 'auto' }}/>
        }else if (icon === 'PDF'){
            return <img src={pdfIcon} alt="pdf" style={{ marginRight: '10px', marginLeft: 'auto' }}/>
        }
    }

    return (
        <MuiButton variant={variant} style={style} onClick={onClick} size={size} disabled={disabled} className={`button-editable ${className}`}>
            {icon && renderIcon(icon)}
            {icon ? <span style={{ marginRight: 'auto' }}>{text}</span> : <span>{text}</span>}
        </MuiButton>
    )
}