import { useContext } from "react";
import MyContainer from "../../../components/Box";
// import Button from "../../../components/Button";
// import Paragraph from "../../../components/Paragraph";
import Title from "../../../components/Title";
import Paragraph from '../../../components/Paragraph'

import { ClientContext } from "../../../context/client";

import theme from "../../../theme";
import phoneFormat from '../../../utils/phone.format';
import phoneIcon from "../../../assets/icons/phone.svg";
import whatsappIcon from "../../../assets/icons/whatsapp.svg";
// import { useHistory } from 'react-router';

export default function Other() {
  const { client } = useContext(ClientContext);
  return (
    <MyContainer>
      <Title
        text={client?.error}
        fontSize={20}
        color={client?.creditorId && theme.creditor[client?.creditorId] ? theme.creditor[client?.creditorId].primary : theme.colors.blue}
        style={{ marginBottom: "32px" }}
      />
      <div style={{ paddingTop: "32px" }}>
      {client.partnerPhone && (
            <>
              <Paragraph
                text={`Em caso de dúvidas relacionadas ao seu débito, clique em um dos botões abaixo e entre em contato com a central de atendimento do seu credor:`}
                paragraph={true}
              />
              <a
                href={`tel:${client.partnerPhone}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid lightgray",
                  borderRadius: "10px",
                  padding: "10px 20px",
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <img
                  style={{ margin: "0 10px", width: "30px" }}
                  src={phoneIcon}
                  alt="icone de telefone"
                />
                {phoneFormat(client.partnerPhone)}
              </a>
            </>
          )}
          {client.partnerWhatsapp && (
            <>
              <br />
              <Paragraph text={`Ou whatsapp:`} paragraph={true} />
              <a
                href={`https://api.whatsapp.com/send?phone=55${client.partnerWhatsapp}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid lightgray",
                  borderRadius: "10px",
                  padding: "10px 20px",
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <img
                  style={{ margin: "0 10px", width: "30px" }}
                  src={whatsappIcon}
                  alt="icone do whatsapp"
                />
                {phoneFormat(client.partnerWhatsapp)}
              </a>
            </>
          )}
      </div>
{/* 
      <div style={{ paddingTop: "32px", marginBottom: '20px' }}>
      <Paragraph text="Por favor entre em contato com a Recovery ligando para <b>0800-779-8899</b> ou clique em um dos botões abaixo:" />
      </div>
      <Button
        text="Ir para o site Recovery"
        color="primary"
        maxWidth="360px"
        margin="20px auto"
        onClick={() => {
          window.location.href = "https://www.gruporecovery.com/";
        }}
      />

      <Button
        text="Falar com a Recovery pelo Whatsapp"
        color="primary"
        maxWidth="360px"
        margin="20px auto"
        onClick={() => {
          window.location.href = "https://api.whatsapp.com/send?phone=551147658402&text=Ol%C3%A1!%20Eu%20gostaria%20de%20obter%20mais%20informa%C3%A7%C3%B5es%20sobre%20meu%20d%C3%A9bito";
        }}
      /> */}
    </MyContainer>
  );
}
