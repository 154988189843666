import {Typography} from "@mui/material";

export default function Paragraph(props) {
    return (
        <Typography fontSize={props.fontSize} 
        align={props.align ? props.align: 'center'} 
        paragraph={props.paragraph} 
        color={props.color} >
            <span dangerouslySetInnerHTML={{__html: `${props.text}`}} 
        className={props.className} />
        </Typography>
    )
}