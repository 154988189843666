/* eslint-disable no-throw-literal */
import React, { useContext, useState, useEffect } from "react";

import Title from "../../../components/Title";
import SubTitle from "../../../components/SubTitle";
import Paragraph from "../../../components/Paragraph";

import { Paper, TextField } from "@mui/material";

import { useLocation, useHistory } from "react-router";
import Button from "../../../components/Button";
import { ClientContext } from "../../../context/client";

import { convertTel } from "../../../utils/document.format";
import { iquiteiApi } from "../../../services";

function SubmitNegotiation() {
  const { client } = useContext(ClientContext);
  const history = useHistory();

  const ticket = useLocation().state.ticket
  const proposal = useLocation().state.proposal;

  const [helperTextTel, setHelperTextTel] = useState(false);
  const [helperTextEmail, setHelperTextEmail] = useState(false);
  const [errorTel, setErrorTel] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    (async () => {
      await iquiteiApi.putRequest(
        `/partners/${client.partnerId}/v2/site/client/${client.clientId}`,
        {
          step: 'INPUT_CLIENT_INFO',
        },
      );
    })()
  }, [client]);

  const onChangeTelValue = (value) => {
    return setTel(convertTel(value));
  }

  const onSendInfoButton = async () => {
    const telFormatted = tel.replace(/[^\d]/g, "");
    // eslint-disable-next-line no-control-regex
    const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    try {
      if (telFormatted.length !== 11 && telFormatted.length !== 10) {
        setHelperTextTel("Favor digitar todos os dígitos do telefone, incluindo DDD")
        setErrorTel(true);
        throw Error;
      }
      setErrorTel(false);
      if (!email.match(emailRegex)) {
        setHelperTextEmail("Favor digite um e-mail válido")
        setErrorEmail(true);
        throw Error;
      }
      setErrorEmail(false);

      await iquiteiApi.putRequest(
        `/partners/${client.partnerId}/v2/site/client/${client.clientId}`,
        {
          step: 'SUBMITED_CLIENT_INFO',
          phone: telFormatted.toString(),
          email,
        },
      );
      const url = '/negotiate/partner/submitNegotiation';
      const location = {
        pathname: url,
        state: {
          proposal: proposal,
          ticket: ticket,
        },
      };

      history.push(location);
      return;
    } catch(e) {
      return;
    };
  }

  return (
    <div style={{ flex: '1', margin: '0 10px' }}>
      <Paper
        elevation={5}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          marginBottom: "10px",
          wordWrap: "break-word",
          marginTop: '20px'

        }}
      >

        <Title
          text={`🎉Parabéns, ${client?.clientName}🎉`}
          paragraph={true}
          fontWeight={500}
          fontSize="25px"
        />
        <Paragraph
          text={`Você deu o primeiro passo para sua liberdade financeira.`}
          paragraph={true}
          fontSize="18px"
        />

        <Paragraph
          text={`O seu acordo já foi enviado ao <b>${client.creditorName}</b>, antes de finalizarmos e você receber seu <b>boleto</b> e a <b>linha digitável</b> para pagamento via Internet Banking, precisamos apenas que nos informe os seguintes dados:`}
          paragraph={true}
          fontSize="18px"
        />
        <div style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          width: '80%',
        }}>
          <label style={{ width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'start', margin: '20px auto'}}>
            <SubTitle
              text="Telefone para contato:"
            />
            <TextField
              style={{
                width: '100%',
              }}
              error={errorTel}
              helperText={helperTextTel}
              onChange={(e) => onChangeTelValue(e.target.value)}
              value={tel}
              size="medium"
              inputProps={{
                type: "tel",
                style: { fontSize: 20},
                maxLength: 15,
              }}
              placeholder="Informe o seu telefone"
            />
          </label>
          <label style={{ width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'start', margin: '20px auto'}}>
            <SubTitle
              text="Email para contato:"
            />
            <TextField
              style={{
                width: '100%',
              }}
              error={errorEmail}
              helperText={helperTextEmail}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              size="medium"
              inputProps={{
                style: { fontSize: 20},
              }}
              placeholder="Informe o seu e-mail"
            />
          </label>
        </div>

        <Button
          text="Enviar informações e gerar boleto"
          color="primary"
          variant="contained"
          onClick={onSendInfoButton}
        />
        
      </Paper>
    </div>
  );
}

export default SubmitNegotiation;
