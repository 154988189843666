/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react'

import MyContainer from '../../components/Box'
import Paragraph from '../../components/Paragraph'
import Button from '../../components/Button'

import { useHistory } from 'react-router'
import { ClientContext } from '../../context/client';
import { useEffect } from 'react'

function About() {
    const { client } = useContext(ClientContext);
    const history = useHistory()
    let home = '/';
 
    useEffect(() => {
      const pathString = sessionStorage.getItem('clientPath');
      if (pathString) {
        home = pathString;
      }
    }, []);

    const navigatePage = () => {
        const path = {
          pathname: `${home}`,
          state: {
            client: client,
            locate: home,
          },
        };
        history.push(path);
      };
    return (
      <MyContainer color="white">
        <Paragraph text={client.partnerInfo} paragraph={true} />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            text="VOLTAR"
            color="transparent"
            maxWidth="300px"
            marginTop="20px"
            size="large"
            onClick={() => navigatePage()}
          />
        </div>
      </MyContainer>
    )
}

export default About
