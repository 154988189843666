import { Backdrop, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme)=>({
    backdrop: {
        zIndex: 1,
        color: '#fff',
    }
}))

export default function MyBackDrop(props) {
    const classes = useStyles()
    return (
        <Backdrop open={props.open} className={`loading ${classes.backdrop}`} >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}