import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { Typography, Snackbar, Alert, Slider } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

import { Popup } from '..';

import Button from '../Button';

import { iquiteiFeedbackService } from '../../services';

const StyleFeedback = styled.div`
  display: flex;
  flex-direction: column; 
  flex: 1;
  width: 100%;

  animation-duration: 0.5s;
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);

  @keyframes animate-pop {
    0% {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
`;

const emojiContainer = {
  display: 'flex', 
  justifyContent: 'space-between',  
  width: '100%'
}

const ContainerFlex = {
  display: 'flex', 
  flexDirection: 'column', 
  width: '100%', 
  marginTop: '20px'
}

const emoji = {
  fontSize: '1.5em',
  cursor: 'pointer'
}

const textAreaStyle = {
  widht: '100%',
  height: '120px',
  borderRadius: '5px',
  padding: '5px',
  marginLeft: '10px',
  marginRight: '10px'
}

const iconClose = {
  display: 'flex',
  flex: 1,
  width: '100%',
  justifyContent: 'flex-end',
}

const buttonContainer = {
  display: 'flex',
  flex: 1,
  width: '100%', 
  alignItems: 'center', 
  justifyContent: 'center'
}

const PopupToasting = ({ type, message, enable, setOpen }) => {
  const vertical= 'top';
  const horizontal = 'center';

  return (
    <>
        <Snackbar
          open={enable} 
          autoHideDuration={2000}
          onClose={setOpen}
          anchorOrigin={{ vertical, horizontal }}
          style={{ alignItems: 'center' }}
        >
          <Alert onClose={setOpen} severity={type} sx={{ width: '100%' }}>
            { message }
          </Alert>
        </Snackbar>
    </>
  )
}

const Feedback = ({ 
  showFeedback, 
  setShowFeedback, 
  clientId, 
  partnerId
 }) => {

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState({
    type: '',
    message: '',
  });

  const [range, setRange] = useState(5);
  const [rangeText, setRangeText] = useState('Médio');
  const [text, setText] = useState('');

  useEffect(() => {
    if(range >= 0 && range < 2){
      setRangeText('Péssimo');
      return;
    }
    if(range >= 2 && range < 4){
      setRangeText('Ruim');
      return;
    }
    if(range >= 4 && range < 6){
      setRangeText('Médio');
      return;
    }
    if(range >= 6 && range < 8){
      setRangeText('Bom!');
      return;
    }
    if(range >= 8 && range <= 10){  
      setRangeText('Excelente!');
      return;
    }
  },[range]);
  
  const handleSubmitFeedback = async () => {
    try {
      const body = {
        clientId: clientId || '',
        feedbackText: text,
        rating: range,
      };

      const data = await iquiteiFeedbackService.insertNewFeedback(partnerId, body);

      if(data !== 'ERRO') {
        setAction({
          type: "success",
          message: "Sucesso ao enviar o Feedback"
        });
        setOpen(true);
      }

      setShowFeedback(false);
    } catch(error) {
      throw(error);
    }
  }

  useEffect(() => { 
    setShowFeedback(true) 
  },[setShowFeedback]);

  return (
    <>
      {showFeedback && (
      <Popup>
        <StyleFeedback>
          <PopupToasting 
            type={action.type || ''} 
            message={action.message || ''} 
            enable={open}
            setOpen={() => setOpen((enable) => !enable)}
          />
          <div style={iconClose}>
            <CloseOutlined 
              style={{ cursor: 'pointer' }}
              onClick={() => setShowFeedback(false)}
            />
          </div>

          <div style={{ justifyContent: 'flex-start' }}>
            <Typography style={{ marginTop: '-10px', fontSize: '1.1em' }}>
              Avalie o nosso serviço:
            </Typography>
          </div>

          <div style={ContainerFlex}>
            <Typography style={{ textAlign: 'center', marginBottom: '2px', fontWeight: 'bold'}}>
              { `Sua avaliação: ${rangeText}` }
            </Typography>
            <div style={emojiContainer} >
              {['😡', '😑', '😁'].map((item, index) => (
                <div key={index} style={ emoji }>
                  { item }
                </div>
              ))}
            </div>
      
            <div style={{ marginLeft: 15, marginRight: 10 }}>
              <Slider
                value={range === null ? (50) : (range*10)}
                color='primary'
                onChange={(value) => 
                  setRange(value.target.value/10)
                } 
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <Typography style={{ marginTop: 10, fontSize: '0.8em' }}>
                Deixe sua feedback (opcional):
              </Typography>
              <textarea
                value={text}
                onChange={(event) => setText(event.target.value)}
                style={ textAreaStyle }
              />
            </div>
            
            <div style={buttonContainer}>
              <Button
                marginTop='15px'
                text='Enviar avaliação'
                color='primary'
                onClick={handleSubmitFeedback}
              />
            </div>

          </div>
        </StyleFeedback>
      </Popup>
    )}
   </>
  );
};

export default Feedback;