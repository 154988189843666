import { useState, useEffect } from 'react';

import { ClientContext } from './client.context';


export const ClientProvider = ({ children }) => {
  const [client, setClient] = useState({
    partnerId: 0,
    partnerName: '',
    partnerInfo: '',
    partnerImage: '',
    creditorImage: '',
    creditorName: '',
    clientId: '',
    clientName: '',
    vars: '',
    clHeaders: '',
  });

  const [proposal, setProposal] = useState({
    NegotiationOffers: [],
    updatedValue: 0
  });

  const [ticket, setTicket] = useState({});

  const [debts, setDebts] = useState({});

  useEffect(() => {
    const clientString = JSON.stringify(client);

    if (client && client.clientName) sessionStorage.setItem('client', clientString);
  }, [client]);

  useEffect(() => {
    const clientSession = sessionStorage.getItem('client');

    if (clientSession) {
      const clientParse = JSON.parse(clientSession);

      if (clientParse) {
        // const today = new Date().getDate();
        // const campaignDate = new Date(clientParse.campaignDate).getDate();

        // if (today === campaignDate) {
          setClient(clientParse);
        // }
      }
    }
  }, []);

  return (
    <ClientContext.Provider value={{
      client,
      setClient,
      proposal,
      setProposal,
      ticket,
      setTicket,
      debts,
      setDebts,
    }}>
      {children}
    </ClientContext.Provider>
  );
};
