/* eslint-disable import/no-anonymous-default-export */
import ExpiredLink from './ExpiredLink';
import NotFound from './NotFound';
import CpfNotFound from './CpfNotFound';
import Other from './Other';

export default {
  ExpiredLink,
  NotFound,
  CpfNotFound,
  Other,
};
