import { LINK } from './iquiteiApi.service';

export const insertNewFeedback = async (partnerId, body) => {
  try {
    const response = await LINK.post(`/partners/${partnerId}/v2/npsFeedback`, body);

    return response.data;
  } catch (error) {
    console.error(error);
    return 'ERRO';
  }
};

export const getAllFeedbacks = async (partnerId) => {
  try {
    const response = await LINK.put(`/partners/${partnerId}/v2/npsFeedback`);

    return response.data;
  } catch (error) {
    console.error(error);
    return '';
  }
};
