import styled from "styled-components";

export const Loading = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgb(38 15 99);
  display: flex;
  justify-content: center;
  align-items: center;
  top:0;
  left: 0;

  
`;

export const LoadingImg = styled.img`
  width: 20%!important;
  animation: blinkAnimation 1s ease 0s infinite normal forwards;

  @keyframes blinkAnimation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.2;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  font-family: "Roboto";
  margin: 0 auto;

  .creditor-partner {
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    padding: 5px 0;
    width: 100%;

    img {
      max-width: 30%;

      @media only screen and (min-width: 900px) {
        max-width: 16%;
      }
    }
  }


  .headline {
    background-repeat: no-repeat;
    background-color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].primary : theme.creditor['0'].primary};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    margin: 0 auto;

    div {
      z-index: 2;

      &.background {
        background-color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].secondary : 'transparent'};
        opacity: 0.4;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 0;
      }
    }

    .logo {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 50%;
      margin: 0 auto;
      img {
        max-width: 60%;

        @media only screen and (min-width: 900px) {
          max-width: 30%;
        }
      }
    }

    .partner-creditor-logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .headline-text {
      font-weight: 700;
      font-size: 30px;
      color: #fcfcfc;
      display: flex;
      justify-content: center;
      text-align: center;
      max-width: 60%;
      margin: 0 auto;
    }
  }

  .footer {
    background-color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].footer : theme.creditor['0'].footer};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 391px;

    @media screen and (max-width: 1450px) {
      padding: 0 50px;
    }

    .footer-text {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      color: #FFFFFF;
    }

    .footer-credor-text {
      margin-top: 40px;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      color: #FFFFFF;
      margin-bottom: 20px;

      b, a {
        color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].destaque : theme.creditor['0'].destaque};
      }
    }
  }

  .business-document {
    background-color: #282828;;
    color: #fcfcfc;
    font-size: 10px;
    font-weight: 300;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
   
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
    padding: 15px 40px;
    
    
    
    @media screen and (max-width: 900px) {
      padding: 15px 0;
     width: 90% !important;
      margin: 0 auto;
    }
    
    background: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].destaque : theme.creditor['0'].destaque};
    color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].whiteText : theme.creditor['0'].whiteText};
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    transition: 0.2s;
    margin: 20px auto;
    
    &:hover {
      transition: 0.2s;
      transform: scale(1.1);
    }
  }

  @media screen and (min-width: 600px) {
    .headline {
      background-position: center;
    }
    
  }

  @media screen and (max-width: 900px) {
    .headline-text {
      max-width: 90% !important;
    }
  }
`;
