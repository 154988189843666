import { createContext } from 'react';

const initialState = {
  client: {},
  setClient: () => {},
  proposal: {},
  setProposal: () => {},
  ticket: () => {},
  setTicket: () => {},
  debts: {},
  setDebts: () => {},
};

const ClientContext = createContext(initialState);

const ClientConsumer = ClientContext.Consumer;

export { ClientContext, ClientConsumer };
