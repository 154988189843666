/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useContext } from "react";
import { TextField, Paper } from "@mui/material";
import { useHistory, useLocation } from "react-router";
import { Container } from "./styled";


import {
  Popup,
  Button,
  Backdrop,
  Paragraph,
  Title,
  Box,
  Tooltip,
} from '../../../components';

import { iquiteiApi } from "../../../services";
import { ClientContext } from '../../../context/client';

import { maskCpf } from "../../../utils/document.format";
import formatMessage from '../../../utils/formatMessage';

export default function ConfirmateCPF() {
  const { client } = useContext(ClientContext);
  let history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [helperText, setHelperText] = useState(false);
  const [error, setError] = useState(false);
  const [cpf, setCpf] = useState("");
  const [inCooldown, toggleCooldown] = useState(false);

  const tipoDado = (client && client.cpf && client.cpf.length === 11) ? 'CPF' : 'CNPJ';

  useEffect(() => {
    setLoading(false);
    sessionStorage.setItem('clientPath', location?.pathname);
    if (client?.partnerId !== 0) {
      (async () => {
      await iquiteiApi.postRequest(
        `/partners/${client?.partnerId}/v2/queue/negotiation?type=access`,
        {
          id: client.clientId,
          document: client.cpf,
        }
      )})();
    }
  }, []);

  const confirmateCpf = async () => {
    try {
      const config = {
        digits: cpf,
        document: client.cpf,
      };
  
      await iquiteiApi
        .postRequest(`/partners/${client?.partnerId}/v2/client/validate`, config);

      await iquiteiApi.postRequest(
        `/partners/${client?.partnerId}/v2/queue/negotiation?type=confirmation`,
        {
          id: client.clientId,
          document: client.cpf,
        }
      );

      history.push('/debts');
    } catch(e) {
      setError(true);
      if (cpf.length < 3) {
        setHelperText("Favor digitar os 3 digitos do CPF");
      } else {
        setHelperText("CPF não confere.");
      }
    }
  };

  const navigatePage = (locate) => {
    const path = {
      pathname: `/${locate}`,
    };
    history.push(path);
  };

  const formatedMessage = (client.negotiateText) ? formatMessage(client.negotiateText, client) : '';

  return (
    <Container creditor={client?.creditorId}>    
      <Box color="white">
      {inCooldown && (
        <Popup>
          <span>
            Por favor, aguardar 30 segundos antes de tentar novamente.
          </span>
          <Button
            text="Sair"
            color="secondary"
            variant="contained"
            maxWidth="300px"
            size="large"
            onClick={() => toggleCooldown(false)}
            marginTop="20px"
          />
        </Popup>
      )}
      <Backdrop open={loading} />
      <Paper
        elevation={3}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "15px",
        }}
      >
       
        <div style={{width: '85%', margin: '0 auto'}}>
        <Title fontFamily='Dosis-semibold' text={`Olá, ${client?.clientName}!`} 
          paragraph={true} align='left'
        />
        <Paragraph className='font-dossis-semibold' text={formatedMessage} align='left' />
        </div>

        <Title text={`${tipoDado}: ${client.cpf ? maskCpf(client.cpf) : ''}`} paragraph={true} margin='20' fontFamily='Dosis-semibold' />

        <Tooltip
          className="tooltip font-dossis-semibold"
          text={'Por que devo confirmar meu CPF?'}
          legend="Por se tratar de informações confidenciais, é necessário uma confirmação bancária a fim de que não repassemos informações a pessoas indevidas. Por segurança, informamos alguns dígitos do seu CPF e pedimos a confirmação apenas dos 3 dígitos marcados com X"
            enterTouchDelay
            leaveTouchDelay={100}
            // onClick={handleTooltipOpen}
        />
        
        <TextField
          className="textarea"
          error={error}
          helperText={helperText}
          onChange={(e) => setCpf(e.target.value)}
          value={cpf}
          size="small"
          inputProps={{
            type: "tel",
            maxLength: 3,
            style: { fontSize: 20, fontFamily: 'Dosis-semibold'},
          }}
          placeholder="Digite os 3 dígitos ocultos do seu CPF para confirmar"
        />

        {/* <span
          style={{
            marginTop: "10px",
            fontSize: "14px",
            textAlign:  "left",
          }}
        >
          Ao clicar em "Confirmar meu CPF" você sinaliza que concorda com os Termos de Uso e Politica de Privacidade da plataforma iQuitei
        </span> */}

        <Button
          text="CONFIRMAR CPF"
          color="primary"
          variant="contained"
          onClick={confirmateCpf}
          size="large"
          marginTop={10}
          align= 'center'
          className='font-dossis-semibold'
          fontFamily='Dosis-semibold'
        />
      </Paper>
      <a className="about" onClick={(event) => navigatePage("about")}>
        <Title text={`Sobre a ${client?.partnerName}`} align="center" fontFamily="Dosis-semibold" />
      </a>
    </Box>
    </Container>  
  );
}
