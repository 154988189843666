import Typography from "@mui/material/Typography";

export default function SubTitle(props) {
    return (
         <Typography
            fontSize={props.fontSize || "20px"} 
            align={ props.align ? props.align: 'center'}
            paragraph={props.paragraph}
            fontWeight="300"
            color={props.color || ''}
         >
             {props.text}            
         </Typography>
        // <h2>
        //      {props.text}   
        // </h2>
    )
}