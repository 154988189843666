
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Layout from './components/Layout'

import Partner from './pages/Partner';
import Negotiation from './pages/Negotiation';
import Preventive from './pages/Preventive';
import Error from './pages/Error';
import About from './pages/About';
import CallMe from './pages/CallMe';

import "./index.css";

export default function App() {
  const LayoutRoute = ({ component: Component, path, ...rest }) => {
    return (
      <Route
        path={path}
        exact
        {...rest}
        render={(props) => {
            return <Layout children={<Component {...props} />} />
        }}
      />
    );
  };

  return (
    <BrowserRouter>
      <Switch>

        <LayoutRoute path="/ticket" component={Preventive.Ticket} />
        <LayoutRoute path="/p/:idShipment/:idPosition" component={Preventive.Home} />

        <LayoutRoute path="/404" component={Error.NotFound} />
        <LayoutRoute path="/expiredlink" component={Error.ExpiredLink} />
        <LayoutRoute path="/cpfNotFound" component={Error.CpfNotFound} />
        <LayoutRoute path="/error" component={Error.Other} />

        <LayoutRoute path="/About" component={About} />
        <LayoutRoute path="/CallMe" component={CallMe} />

        <LayoutRoute path="/negotiate/partner/validate" component={Partner.ConfirmateCpf} />
        <LayoutRoute path="/negotiate/partner/debts" component={Partner.Debts} />
        <LayoutRoute path="/negotiate/partner/proposal" component={Partner.Proposal} />
        <LayoutRoute path="/negotiate/partner/confirmateProposal" component={Partner.ConfirmateProposal} />
        <LayoutRoute path="/negotiate/partner/submitClientInfo" component={Partner.SubmitClientInfo} />
        <LayoutRoute path="/negotiate/partner/submitNegotiation" component={Partner.SubmitNegotiation} />
        <LayoutRoute path="/negotiate/partner/:hash" component={Partner.Home} />

        <LayoutRoute path="/submitNegotiation" component={Negotiation.SubmitNegotiation} />
        <LayoutRoute path="/confirmateProposal" component={Negotiation.ConfirmateProposal} />
        <LayoutRoute path="/proposal" component={Negotiation.Proposal} />
        <LayoutRoute path="/debts" component={Negotiation.Debts} />
        <LayoutRoute path="/validate" component={Negotiation.ConfirmateCpf} />
        <Route path="/:idShipment/:idPosition" component={Negotiation.Home} />

        <Route
          path="/"
          component={() => {
            window.location.href = "https://www.iquitei.com.br";
            return null;
          }}
        />
      </Switch>
    </BrowserRouter>
  );
}
