const theme = {
  colors: {
    primary: "#0D1A29",
    secondary: "#E0E1DD",
    tertiary: "#32936F",
    lightGrey: "#D9D9D9",
    light: "#FAFAFA",
    dark: "#303030",
    red: "#B23A3A",
    blue: "#3A78B2",
    lightblue: "#00D1F1",
    green: "#5EBB00",
    subtitle: "#878787",
    backgroundPop: 'rgb(21, 8, 56, 0.81)',
    loading: {
      background: '#0d1a29cc',
      icon: '#E0E1DD'
    }
  },
  text: {
    size: {
      xs: "10px",
      sm: "12px",
      md: "14px",
      lg: "20px",
      xl: "25px",
      xxl: "35px",
    }
  },
  border: {
    radius: "5px",
    color: "#c7c7c7",
  },
  flexGrid: {
    gap: "15px",
  },
  boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)",
  creditor: {
    0: {
      destaque: '#00e48d',
      whiteText: '#FCFCFC',
      blackText: '#232323',
      primary: '#150838',
      secondary: '#00D1F1',
      third: 'rgb(97, 124, 249)',
      fourth: '#0087A8',
      footer: '#260f63',
    },
    8: {
      destaque: '#09A942',
      whiteText: '#FCFCFC',
      blackText: '#232323',
      primary: '#00418E',
      secondary: '#00D1F1',
      third: '#09A942',
      fourth: '#0087A8',
      footer: '#3E3E3E',
    }
  },
};

export default theme;
