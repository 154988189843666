/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Paper } from "@mui/material";

import { ClientContext } from '../../../context/client';

import { iquiteiNegotiations, iquiteiApi } from "../../../services";
import MyBackDrop from "../../../components/Backdrop";
import Paragraph from "../../../components/Paragraph";
import Button from "../../../components/Button";
import { Title } from "../../../components";

import { Container } from './styled';

function Debts() {
  const [loading, setLoading] = useState(true);
  const { setClient, client, proposal, setProposal } = useContext(ClientContext);
  let history = useHistory();

  let path = '';

  useEffect(() => {
    const pathString = sessionStorage.getItem('clientPath');
    if (pathString) {
      path = pathString;
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    if (client && client.partnerId && client.creditorId && client.idQuery) {
      (async () => {
      
      try {
        const proposals = await iquiteiNegotiations.getRequest(
          `/partners/${client.partnerId}/negotiationsOffers/${client.creditorId}/${client.idQuery}`
        );
        await iquiteiApi.putRequest(
          `/partners/${client.partnerId}/v2/site/client/${client.clientId}`,
          {
            step: 'SELECT_DEBT',
            name: proposals?.data?.data?.name,
          },
        );
        if (client.creditorId === 10) {
          const path = {
            pathname: "/negotiate/partner/proposal",
          };

          history.push(path);
        } else {
          setClient({...client, clientName: proposals.data.data.name })
          if (proposals.data.data.debts.length !== undefined) { //NAO REDIRECIONA -> CLIENTE COM MAIS DE 1 DEBITO
            setProposal(proposals.data.data);
          }else{ //REDIRECIONA -> CLIENTE SÓ COM 1 DÉBITO
              const path = {
                  pathname: "/negotiate/partner/proposal",
                  state: {
                    debt: proposals.data.data.debts,
                    proposal: proposals.data.data,
                  },
                };
          
                history.push(path);
          }
        }
        setLoading(false);
      } catch (error) {
          setLoading(false);
          let location = "";
          if (error.response && error.response.status === 410) {
            location = {
              pathname: `/expiredLink`,
              state: {
                client,
              },
            };
          } else if (error.response && error.response.status === 309) {
            location = { pathname: '/', state: { cooldown: true } };
          } else if (error.response && error.response.status === 400) {
            location = {
                pathname: `/cpfNotFound`,
                state: {
                    client,
                    error,
                }
            }
          } else if (error.response && error.response.status === 401) {
            location = { pathname: path };
          } else if (error.response && error.response.status === 402) {
            setClient({...client, error: error.response.data.error});
            location = { pathname: '/error' };
          } else {
            location = {
              pathname: "/404",
              state: {
                lastState: "/",
                description: "Erro ao buscar cliente pelo ID",
                myThrow: error.message,
                status: error.response?.status,
                payload: error.request?.responseURL,
              },
            };
          }
          history.push(location);
      }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debtSelected = (event, index) => {
    
    const path = {
        pathname: "/negotiate/partner/proposal",
        state: {
          debt: proposal.debts[index],
          proposal: proposal,
        },
      };

      history.push(path);
  };

  const formatMoney = (value) => {
    const numberSplit = Number(value).toFixed(2).split('.');
    const valueSplit = numberSplit[0].split('');

    if (valueSplit.length > 3) {
      let newNumber = '';

      for (let index = (valueSplit.length - 1); index >= 0; index -= 1) {
        if (index % 3 === 0 && index !== (valueSplit.length - 1)) {
          newNumber = valueSplit[index] + '.' + newNumber;
        } else {
          newNumber = valueSplit[index] + newNumber;
        }
      }
      return `${newNumber}, ${numberSplit[1]}`;
    }

    return Number(value).toFixed(2).replace('.', ',');
  };

  const countDays = (date) => {
    const today = new Date();
    const newDate = new Date(date);
    const count = today - newDate;
    const days = Math.floor(count / 1000 / 60 / 60 / 24);
    return days;
  };

  const getDebtType = (item) => {
    if (item["a:Operacoes"]["a:DeudaOperacion"]["a:SubTipoProducto"]) {
      return `${item["a:Operacoes"]["a:DeudaOperacion"]["a:SubTipoProducto"]} - ${item["a:Operacoes"]["a:DeudaOperacion"]["a:TipoProducto"]}`;
    }
    return `${item["a:Operacoes"]["a:DeudaOperacion"][0]["a:SubTipoProducto"]} - ${item["a:Operacoes"]["a:DeudaOperacion"][0]["a:TipoProducto"]}`;
    
  };

  const getDataType = (item) => {
    if (item["a:Operacoes"]["a:DeudaOperacion"]['a:FechaInicioMora']) {
      return item["a:Operacoes"]["a:DeudaOperacion"]['a:FechaInicioMora'];
    }
    return item["a:Operacoes"]["a:DeudaOperacion"][0]['a:FechaInicioMora'];
    
  };

  return (
    <Container>
      <MyBackDrop open={loading} />

      <Paper
        elevation={3}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "60px",
        }}
      >
        <Title
          align="left"
          text={`${client?.clientName},`}
          fontSize="25px"
          paragraph
        />
        <Paragraph
          align="left"
          text={`Encontramos mais de um débito vinculado ao seu CPF, escolha qual deseja negociar abaixo:`}
          fontSize="18px"
          paragraph={true}
        />
        <List>
          {proposal?.debts?.map((item, index) => (
            <div>
              <ListItem
                divider
                className="list-items"
              >
                <div>
                  <span style={{ display: 'block', fontSize: "18px" }}>
                    <b>{item["a:Cartera"]}</b>
                  </span>
                  <span style={{ display: 'block', fontSize: "18px" }}>
                    {getDebtType(item)}
                  </span>
                  <span style={{ display: 'block', fontSize: "18px" }}>
                    Valor: <b>R$ {formatMoney(item['a:Total'])}</b>
                  </span>
                  <span style={{ display: 'block', color: 'red', fontSize: "18px" }}>
                    {countDays(getDataType(item))} dias de atraso
                  </span>
                </div>
                <Button
                  className={"select-proposal"}
                  text="VER PROPOSTAS"
                  color="primary"
                  variant="contained"
                  maxWidth="300px"
                  padding="16px 20px"
                  margin="10px 0"
                  onClick={(event) => debtSelected(event, index)}
                />
              </ListItem>
            </div>
          ))}
        </List>
      </Paper>
    </Container>
  );
}

export default Debts;
