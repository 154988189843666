import React, { useState, useEffect } from 'react';


import { Slider, Box } from '@mui/material';
import { Typography } from '@mui/material';

import theme from '../../theme'

import Title from '../Title';

import { formatValue } from '../../utils/value.format';


export default function SliderProposal({ proposal, typeNegotiate,  setSelectedProposal, creditor }) {

    const [offers] = useState([])
    const [value, setValue] = useState(0)
    const [idealOffer, setIdealOffer] = useState([])
    const [mostraParcelas, setMostraParcelas] = useState(false);
    

    useEffect(() => {
        proposal.NegotiationOffers.map((item) => (
            offers.push({
                value: parseFloat(item.DownPaymentAmount),
                label: `R$ ${item.DownPaymentAmount}`,
                TotalInstallments: parseInt(item.TotalInstallments),
                proposal: item
            })
        ))

        setValue(offers[offers.length - 1]?.value) //Inicia o value para a proposta já começar com o menor valor disponível
        setSelectedProposal(idealOffer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proposal, offers])

    useEffect(() => {
        if (offers.length > 0) {
            var idealOffer;
            //ordenando array por valor
            offers.sort(function (a,b){
                return a.value < b.value ? 1 : a.value > b.value ? -1 : 0;
            });

            if (typeNegotiate === 1) {
                idealOffer = offers.reduce(function (prev, curr) {
                    return (Math.abs(curr.value - value) < Math.abs(prev.value - value) ? curr : prev);
                });
                setSelectedProposal(idealOffer)
                setIdealOffer(idealOffer)
            } else {
                //ordenando array por parcela
                offers.sort(function (a,b){
                    return a.TotalInstallments < b.TotalInstallments ? -1 : a.TotalInstallments > b.TotalInstallments ? 1 : 0;
                });

                idealOffer = offers.reduce(function (prev, curr) {
                    return (Math.abs(curr.TotalInstallments - value) < Math.abs(prev.TotalInstallments - value) ? curr : prev);
                });
                setSelectedProposal(idealOffer)
                setIdealOffer(idealOffer)
            }
        }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, idealOffer, typeNegotiate])

    const handleChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            setValue(newValue);
        }
    };

    const slider = (typeNegotiate) => {
        if (typeNegotiate === 1) {
            return (
                <Slider
                    value={value ?? 0}
                    max={offers[0]?.value ?? 0}
                    min={offers[offers.length - 1]?.value ?? 0}
                    valueLabelDisplay="on"
                    valueLabelFormat={(x) => `R$ ${x.toFixed(2)}`}
                    onChange={handleChange}
                    fontFamily= 'Dosis-medium'
                    sx={{
                        color: (creditor && theme.creditor[creditor]) ? theme.creditor[creditor].primary : theme.colors.blue,
                    }}
                //step={null}
                //marks={offers}
                />
            )
        } else {
            return (
                <Slider
                    value={value}
                    max={offers[offers.length - 1]?.TotalInstallments}
                    min={offers[0]?.TotalInstallments}
                    valueLabelDisplay="on"
                    valueLabelFormat={(x) => `Parcelas: ${x}`}
                    onChange={handleChange}
                    fontFamily='Dosis-medium'
                    sx={{
                        color: (creditor && theme.creditor[creditor]) ? theme.creditor[creditor].primary : theme.colors.blue,
                    }}
                //step={null}
                //marks={offers}
                />
            )
        }

    }

    const findProposalOfValue = () => {
        if(Number(creditor) === 6) {
            // const parcela = {};

            // idealOffer?.proposal?.parcelasParcelamento.forEach((parc) => {
            //     if (parcela[formatValue(parc.valorPrincipal)]) parcela[formatValue(parc.valorPrincipal)] += 1;
            //     else parcela[formatValue(parc.valorPrincipal)] = 1;
            // });

            return (
                <div style={{ width: '100%', height: mostraParcelas ? 'unset' : '100px', position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between', overflowY: 'hidden' }}>
                    {idealOffer?.proposal?.parcelasParcelamento.map((parc) => {
                        return <Title fontFamily='Dosis-medium' text={`${parc.numeroParcela}ª de ${formatValue(parc.valorPrincipal)}` } />
                        
                    })}
                    <button
                        onClick={() => setMostraParcelas(!mostraParcelas)}
                        style={{
                            position: 'absolute',
                            right: '0',
                            top: '0',
                            border: '1px solid gray',
                            borderRadius: '10px',
                            padding: '10px 20px',
                            background: 'transparent',
                            color: 'blue',
                            fontWeight: 'bold'
                        }}>{mostraParcelas ? 'Esconder' : 'Ver mais'}</button>
                </div>
            );
        }
        
        return (
            <Title fontFamily='Dosis-medium' text={`${idealOffer.TotalInstallments}x de ${formatValue(idealOffer.value)}` } />
        );

    }


    return (
        <Box
            width={'100%'}
            style={{
                padding: '8px',
                margin: '8px',
                maxWidth: '500px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <Typography style={{ fontSize: '16px', textAlign: 'center', fontWeight: 400, lineHeight: 1.5, color: theme.colors.subtitle,fontFamily: 'Dosis-medium' }}>
                Arraste o ponto até o valor da parcela que cabe no seu bolso
                <br />
            </Typography>

            {slider(typeNegotiate)}

            {findProposalOfValue()}


        </Box >
    );
}