import styled from "styled-components";
import theme from "../../../theme";

const Popup = styled.div`
  background-color: ${theme.colors.backgroundPop};
  color: ${theme.colors.primary};
  position: fixed;
  font-size: ${theme.text.size.xl};
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 2000;
  display: flex;
  justify-content: center;


  padding: 10px;
  div.children {
    align-items: center;
    background-color: ${theme.colors.light};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
    position: fixed;
    top: 60px;
    left: 0;
  }
  @media screen and (min-width: 600px) {
    div.children {
      top: 10%;
      left: 29.5%;
      width: 35%;
    }
  }
`;

export default function PopupContainer({ children }) {
  return (
    <Popup>
      <div className="children">{children}</div>
    </Popup>
  );
}
