import ComponentBackdrop from './Backdrop';
import ComponentBox from './Box';
import ComponentBoxName from './BoxName';
import ComponentButton from './Button';
import ComponentFeedback from './Feedback';
import ComponentFooter from './Footer';
import ComponentParagraph from './Paragraph';
import ComponentPopup from './Pop-up';
import ComponentProposalDeny from './ProposalDeny';
import ComponentSlider from './Slider';
import ComponentSubTitle from './SubTitle';
import ComponentTimerBox from './TimerBox';
import ComponentTitle from './Title';
import ComponentTooltip from './Tooltip';

export const Backdrop = ComponentBackdrop;
export const Box = ComponentBox;
export const BoxName = ComponentBoxName;
export const Button = ComponentButton;
export const Feedback = ComponentFeedback;
export const Footer = ComponentFooter;
export const Paragraph = ComponentParagraph;
export const Popup = ComponentPopup;
export const ProposalDeny = ComponentProposalDeny;
export const Slider = ComponentSlider;
export const SubTitle = ComponentSubTitle;
export const TimerBox = ComponentTimerBox;
export const Title = ComponentTitle;
export const Tooltip = ComponentTooltip;
