/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Paper } from "@mui/material";

import Title from "../../../components/Title";
import Paragraph from "../../../components/Paragraph";
import MyBackdrop from "../../../components/Backdrop";
import Button from "../../../components/Button";
import { ClientContext } from "../../../context/client";

import { iquiteiApi } from '../../../services';



export default function Home() {
  const { setClient, client } = useContext(ClientContext);
  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const [loading, setLoading] = useState(false);

  const getPartnerByHash = async (hash) => {
    try {
      const response = await iquiteiApi.getRequest(`/partners/hash/${hash}`);
      const data = response.data.data;

      const clientIdentify = await iquiteiApi.postRequest(
        `/partners/${data.partnerId}/v2/site/client`,
        {
          creditorId: 8,
          step: 'SELECT_CREDITOR',
        },
      );

      setClient({
        ...client,
        ...data,
        clientId: clientIdentify.data.data.insertId,
      });

      return response.data.data;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await getPartnerByHash(params.hash);

        setLoading(false);
      } catch (error) {
        const locationBody = {
          pathname: "/404",
          state: {
            lastState: "/",
            description: "Erro ao buscar cliente pelo ID",
            myThrow: error.message,
            status: error.response?.status,
            payload: error.request?.responseURL,
            origin: location,
          },
        };

        setLoading(false);

        history.push(locationBody);
      }
    })();

    // eslint-disable-next-line
  }, [history, location]);

  useEffect(() => {
    if (client && client.creditors) {
      client.creditors.forEach((item) => {
        if (item.id === 8) {
          onSelectCredorButton(item)
        }
      })
    }
  }, [client]);

  const onSelectCredorButton = (credor) => {
    setClient({
      ...client,
      partnerWhatsapp: credor.whatsapp,
      partnerPhone: credor.phone,
      creditorId: credor.id,
      creditorName: credor.name,
      partnerInfo: credor.about,
    })

    history.push('/negotiate/partner/validate');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px auto' }}>
      <MyBackdrop open={loading} />

      <Paper
        elevation={5}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "60px 70px",
        }}
      >
        <div
          style={{
            width: '75%',
            margin: '0 auto',
          }}
        >
          <Title fontSize={'25px'} text={'Olá,'} paragraph={true} align="left"/>
          <Paragraph fontSize={'18px'} text={'Antes de mais nada precisamos saber em qual credor deseja buscar informações.'} paragraph={true} align="left"/>
        </div>

        <List>
          {client?.creditors?.filter((item) => item.id === 8) // Remover filter quando puder ter de outros credores além da Recovery
          .map((item, index) => (
            <div>
              <ListItem
                divider
                style={{
                  alignItem: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '90%',
                  margin: '0 auto',
                }}
              >
                <img src={item?.image} alt={item.name} style={{ width: '70%', margin: '10px 0' }}/>
                <Button
                  className={"select-proposal"}
                  text="VERIFICAR PROPOSTAS"
                  color="primary"
                  variant="contained"
                  padding="16px 20px"
                  maxWidth="100%"
                  margin="10px 0"
                  onClick={() => onSelectCredorButton(item)}
                />
              </ListItem>
            </div>
          ))}
        </List>
      </Paper>
    </div>
  );
}
