/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useContext } from "react";

//Components
import Paragraph from "../../../components/Paragraph";
import TimerBox from "../../../components/TimerBox";
import Button from "../../../components/Button";
import { Paper } from "@mui/material";
import MyBackDrop from '../../../components/Backdrop'

import Title from "../../../components/Title";
import { formatValue } from "../../../utils/value.format";
import { decimal } from "../../../utils/2decimal.format";

import { useLocation, useHistory } from "react-router";

import { ClientContext } from '../../../context/client';

import theme from "../../../theme";
import {
  iquiteiApi,
  iquiteiNegotiations,
} from '../../../services';

function ConfirmateProposal() {
  const { client } = useContext(ClientContext);
  const proposal = useLocation().state.item.proposal;

  const [timer, setTimer] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false)

  let path = '';

  useEffect(() => {
    const pathString = sessionStorage.getItem('clientPath');
    if (pathString) {
      path = pathString;
    }
  }, []);
  const backProposal = useCallback(() => {
    const location = {
      state: { client },
    };
    history.push(location);
  }, [client, history]);

  useEffect(() => {
    if (timer) {
      backProposal();
    }
  }, [timer, backProposal]);

  const submitNegotiation = async (proposal) => {
    try {
      setLoading(true)

      const [,, arrangement] = await Promise.all([
        iquiteiApi.putRequest(
          `/partners/${client.partnerId}/v2/site/client/${client.clientId}`,
          {
            step: 'PROPOSAL_CONFIRMATED',
          },
        ),
        iquiteiApi.postRequest(
          `/partners/${client.partnerId}/v2/site/negotiate`,
          {
            id: client.clientId,
            portionAmount: proposal.DownPaymentAmount,
            portion: proposal.TotalInstallments,
            amount: proposal.DownPaymentAmount * proposal.TotalInstallments,
            debt: proposal.DebtTotalBalance,
            negotiationExpiration: proposal.lastDueDate,
            dealId: proposal.NegotiationID || 0,
          }
        ),
        iquiteiNegotiations.postRequest(
          `/partners/${client.partnerId}/submitArrangement/${client.creditorId}`,
          {
            client: client,
            proposal: proposal,
          }
        ),
      ]);
      
      const ticket = await iquiteiNegotiations.postRequest(
          `/partners/${client.partnerId}/ticketArrangement/${client.creditorId}`,
          {
            client: client,
            data: arrangement.data.data,
            proposal: proposal,
          }
        );
      const url = "/negotiate/partner/submitClientInfo";
      const location = {
        pathname: url,
        state: {
          proposal: proposal,
          ticket: ticket.data,
        },
      };

      history.push(location);
      setLoading(false);
    } catch (error) {
      console.log(error, 'ERRO CONFIRMAR PROPOSTA')
      const location = {
        pathname: "/404",
        state: {
          lastState: "/",
          description: "Erro ao buscar cliente pelo ID",
          myThrow: error.message,
          status: error.response?.status,
          payload: error.request?.responseURL,
        },
      };
      history.push(location);
      setLoading(false);
    }
  };

  return (
    <div style={{ flex: '1', margin: '15px auto' }}>
      <MyBackDrop open={loading} />

      <Paper
        elevation={3}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "40px",
          margin: '0 10px'
        }}
      >
        <div style={{ alignSelf: "flex-start" }}>
          <Title
            align="left"
            text={`${client?.clientName},`}
            paragraph
          />

          <Paragraph
            align="left"
            text="Com o pagamento da parcela, o credor providenciará a exclusão do nome nos Órgãos de Proteção ao Crédito com relação ao saldo deste contrato em caso de constar registro."
            paragraph
          />
        </div>

        <Paragraph text="<b>Conforme selecionado, assim ficou sua proposta:</b>"  paragraph/>

        <Paragraph
          text={`Valor atual com desconto: ${formatValue(proposal.TotalInstallments * proposal.DownPaymentAmount)}`}
          fontSize="16px"
          color={theme.colors.lightblue}
        />

        <div style={{ display: 'flex', alignItems: 'center'}}>
          <Paragraph
            text={`Pagamento em `}
            fontSize="16px"
          />
          <Title
            text={` ${proposal.TotalInstallments}x de ${formatValue(
              proposal.DownPaymentAmount
            )}`}
            fontSize="22px"
            margin="0 5px"
          />
        </div>

        <Paragraph
          text={`Vencimento em ${proposal.lastDueDate}`}
          fontSize="16px"
        />
        <Paragraph
          text={`Desconto especial de ${decimal(proposal.DiscountRate, 2)}%`}
          color={theme.colors.blue}
          fontSize="16px"
        />

        <Paragraph
          text={`Você economizará  ${formatValue(
            proposal.DebtTotalBalance -
              proposal.DownPaymentAmount * proposal.TotalInstallments
          )}`}
          color={theme.colors.green}
          fontSize="16px"
        />

        <TimerBox timer={timer} setTimer={setTimer} />

        <Button
          text="CONFIRMAR NEGOCIAÇÃO"
          color="primary"
          variant="contained"
          size="large"
          marginTop="32px"
          onClick={() => submitNegotiation(proposal)}
        />
      
      </Paper>
      <div style={{ textAlign:'center' }}>
        <Button
          text="VOLTAR"
          color="transparent"
          marginTop="20px"
          size="large"
          onClick={(event) => history.push(path)}
        />
      </div>
    </div>
  );
}

export default ConfirmateProposal;
