import { styled } from '@mui/material';
import Container from '@mui/material/Container';


const MyContainer = styled(Container)((props) => ({
    backgroundColor: props.color,
    display: 'flex !important' ,
    // alignItems: 'right',
    fontSize: '18px',
    flexDirection: 'column',
    width: '100%',
    padding: '10px',
    minHeight: '400px',
    margin: '20px auto !important',
  }));


export default MyContainer

