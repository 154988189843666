import React, { useContext, useState } from "react";

import Title from "../../../components/Title";
import Paragraph from "../../../components/Paragraph";

import { Paper } from "@mui/material";
import { formatValue } from "../../../utils/value.format";

import { useLocation } from "react-router";
import Button from "../../../components/Button";
import theme from "../../../theme";
import { ClientContext } from "../../../context/client";

import Feedback from "../../../components/Feedback";
import { Container } from "./styled";

function SubmitNegotiation() {
  const { client } = useContext(ClientContext);

  const [showFeedback, setShowFeedback] = useState(true);
  const [mostraParcelas, setMostraParcelas] = useState(false);

  const ticket = useLocation().state.ticket.data
  const proposal = useLocation().state.proposal;

  const downloadTicket = () =>{
    window.open(ticket.link);
  }

  return (
    <Container creditor={client?.creditorId}>
      <Paper
        elevation={3}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "30px",
          marginBottom: "10px",
          wordWrap: "break-word",
          marginTop: '4 0px'

        }}
      >
        <Feedback 
          showFeedback={showFeedback}
          setShowFeedback={setShowFeedback}
          clientId={client?.clientId}  
          partnerId={client?.partnerId}
        />

        <Title
          text={`🎉Parabéns, ${client?.clientName}🎉`}
          paragraph={true}
          fontWeight={500}
          fontSize="25px"
           
        />
        <Paragraph
          text={`Você deu o primeiro passo para sua liberdade financeira.`}
          paragraph={true}
          fontSize="18px"
          className="font-dossis-medium"
        />

        <Paragraph
          text={`O seu acordo foi gravado na ${client.creditorName}. Com o pagamento da primeira parcela, o credor providenciará a exclusão do nome nos Órgãos de Proteção ao Crédito com relação ao saldo deste contrato em caso de constar registro.`}
          paragraph={true}
          fontSize="18px"
          className="font-dossis-medium"
        />
        <div style={{width: '100%'}}> {/* Feito para casos com o digitableLine sem espaços, o texto precisava de uma div com tamanho ao redor para quebrar linha */}
          <Title
            text={`Seu acordo:`}
            paragraph={true}
            fontSize="18px"
            fontFamily="Dosis-semibold"
          />

          <Paragraph
            text={`Valor total do seu acordo: ${Number(client?.creditorId) === 6 ? formatValue(Number(proposal.DebtTotalBalance) - Number(proposal.DiscountRate)) : formatValue(proposal.TotalInstallments * proposal.DownPaymentAmount)}`}
            fontSize="16px"
            color={client?.creditorId && theme.creditor[client?.creditorId] ? theme.creditor[client?.creditorId].secondary : theme.colors.lightblue}
            className="font-dossis-medium"
          />

          <div style={{ display: 'flex', alignItems: 'center', position: 'relative', flexDirection: Number(client?.creditorId) === 6 ? 'column' : 'row', width: '100%' }}>
            <Paragraph
              text={`Pagamento em `}
              fontSize="16px"
              className="font-dossis-medium"
            />
            {Number(client?.creditorId) === 6 ? 
            <div style={{ width: '100%', height: mostraParcelas ? 'unset' : '100px', position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between', overflowY: 'hidden' }}>
            {proposal?.parcelasParcelamento.map((parc) => {
                return <Title fontFamily='Dosis-medium' text={`${parc.numeroParcela}ª de ${formatValue(parc.valorPrincipal)}` } />
            })}
            <button
                onClick={() => setMostraParcelas(!mostraParcelas)}
                style={{
                    position: 'absolute',
                    right: '0',
                    top: '0',
                    border: '1px solid gray',
                    borderRadius: '10px',
                    padding: '10px 20px',
                    background: 'transparent',
                    color: 'blue',
                    fontWeight: 'bold'
                }}>{mostraParcelas ? 'Esconder' : 'Ver mais'}</button>
            </div>
            : 
            <Title
              text={` ${proposal.TotalInstallments}x de ${formatValue(
                proposal.DownPaymentAmount
              )}`}
              fontSize="25px"
              margin="5px"
              fontFamily="Dosis-semibold"
            />}
          </div>

          <Paragraph
            text={`Vencimento em ${proposal.lastDueDate}`}
            fontSize="18px"
            paragraph
            className="font-dossis-medium"
          />
          
          <Paragraph
            text={`Linha digitável:`}
            fontSize="18px"
            className="font-dossis-medium"
          />
          <Paragraph
            text={`<b>${ticket.digitableLine}</b>`}
            paragraph={true}
            fontSize="18px"
            className="font-dossis-medium"
          />
        </div>

        <Button
          text="COPIAR LINHA DIGITÁVEL"
          color="primary"
          variant="contained"
          onClick={() => navigator.clipboard.writeText(ticket.digitableLine)}
          fontFamily= 'Dosis-medium'
        />
        <Button
          text="Baixar boleto da primeira parcela"
          color="primary"
          variant="contained"
          icon="PDF"
          onClick={() => downloadTicket()}
          fontFamily= 'Dosis-medium'
        />
        
      </Paper>
    </Container>
  );
}

export default SubmitNegotiation;
