import { useContext } from 'react';
import { useHistory } from 'react-router';
import { Container } from "./styled";

import Footer from '../Footer';
import BoxName from '../BoxName';
import Button from '../Button';
import { ClientContext } from '../../context/client';

import iquiteiLogo from '../../assets/images/iquitei-logo.svg';


export default function Layout({ children }) {
  const history = useHistory();
  const { client } = useContext(ClientContext);

  return (
    <Container creditor={client?.creditorId}>
      <div style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        overflow: 'scroll'
        
      }}>
        <header className='creditor-partner'>
          <img src={client.partnerImage} alt={client.partnerName} />
          <img
            src={client.creditorImage || 'https://firebasestorage.googleapis.com/v0/b/vhg-dash-prod.appspot.com/o/partnerImages%2Fsem%20borda1.png?alt=media&token=4b85932c-3515-40ae-be6b-e6ae59c6115b'}
            alt="logo"
          />
        </header>
        <BoxName
        align='center'
          name={client.partnerName}
        />
        {children}
        {(client && client.supportActive === 1) && (
        <Button
          text="CENTRAL DE ATENDIMENTO"
          color="secondary"
          variant="contained"
          maxWidth="80%"
          size="large"
          marginTop="0px"
          fontFamily='Dosis-medium'
          onClick={(event) => history.push("/callMe")}
        />
        )}
        <Footer />
      </div>
      <div
        className="image-side"
        style={{
        backgroundImage: `url(${client.headlineImage || 'https://firebasestorage.googleapis.com/v0/b/vhg-dash-prod.appspot.com/o/utils%2FRectangle_24.jpg?alt=media&token=573b20d8-61ff-410a-9d41-0f52620210dd'})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '100vh',
        height: '100%',
        width: '100%',
      }}
      >
        <div className='background'></div>
        <div className='creditor-image'>
          <img style={{ width: '30%' }} src={client.creditorImage || iquiteiLogo} alt='Logo' no-repeat />
        </div>
        <div style={{ scale: '0', height: '100vh' }} />
      </div>
    </Container>
  )
}
