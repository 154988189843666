import styled from "styled-components";

export const Container = styled.div`
  margin: 79px auto;
  padding: 0 219px;

  @media screen and (max-width: 1450px) {
    padding: 0 70px;
  }

  .reasons-title {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].primary : theme.creditor['0'].primary};
    text-transform: uppercase;
    margin-left: 20px;
        
    @media screen and (max-width: 1450px) {
      margin-left: 0px;
    }
  }
  
  .fill {
    color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].third : theme.creditor['0'].third};
  }

  .reason-list {
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .reason-card {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    background: #FFFFFF;
    color: #fcfcfc;

    box-shadow: 0px 11px 20px rgba(21, 8, 56, 0.2);
    border-radius: 8px;
    
    width: 353px;
    
    padding: 20px 50px;
    margin: 0 10px;
    margin-top: 20px;
    margin-bottom: 72px;

    @media screen and (max-width: 1450px) {
      width: 100%;
    }

    .reason-icon {
      display: flex;
      
      justify-content: center;
      align-items: center;

      width: 110px;
      height: 110px;

      background: #fcfcfc;
      
      border-radius: 50%;

      margin-bottom: 18px;

      img {
        width: 120px;
        height: 120px;
      }
    }

    .reason-title {
      margin: 10px 0;

      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      
      line-height: 32px;
      text-align: center;

      color: #000000;
    }

    .reason-description {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      text-align: center;

      color: #000000;

    }
  }

  @media only screen and (min-width: 800px) {
    .reason-card {
      height: 387px;
    }
  }
`;
