import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";

import App from './App';
import { ClientProvider } from './context/client';

import './fonts/Dosis-Bold.ttf';
import './fonts/Dosis-Medium.ttf';
import './fonts/Dosis-SemiBold.ttf';
import theme from "./theme";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ClientProvider>
      <App/>
    </ClientProvider>
  </ThemeProvider>,
  document.getElementById("root")
);

