import { Container } from './style';

import {ReactComponent as ReactLogo} from '../../../../../assets/images/about-credor-image.svg';

export default function AboutCredorHome({ partnerName, partnerAbout, creditor }) {
  return (
    <Container creditor={creditor}>
      <span className="title font-dossis-bold">SOBRE <span className="destaque">A {partnerName.toUpperCase() || ''}</span></span>

      <div className='content'>
        <span className="about font-dossis-semibold">
          <p dangerouslySetInnerHTML={{__html: `${partnerAbout}`}} />
        </span>
        <ReactLogo />
      </div>
    </Container>
  );
};
