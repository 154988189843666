import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  height: 100%;

  padding: 0 219px;

  @media screen and (max-width: 1450px) {
    padding: 0 50px;
  }

  background-color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].primary : theme.creditor['0'].primary};

  margin-top: 94px;
  
  .container {
    padding: 65px 0;
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;

    text-transform: uppercase;

    color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].whiteText : theme.creditor['0'].whiteText};
  
    .destaque {
      color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].third : theme.creditor['0'].third};
    }
  }

  .content {
    display: flex;
    align-items: center;
    
    margin-top: 51px;
    margin-bottom: 74px;

    @media screen and (max-width: 1450px) {
      flex-direction: column;
      align-items: center;;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      /* text-align: justify; */

      color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].whiteText : theme.creditor['0'].whiteText};
    }

    .about {
      margin-left: 98px;

      @media screen and (max-width: 1450px) {
        margin: 40px;
      }
    }
  }

  svg {
    fill: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].secondary : theme.creditor['0'].secondary};
    width: 100%;
  }

  @media only screen and (min-width: 900px) {
    svg {
      width: 50%;
    }
  }
`;
