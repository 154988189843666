import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const TooltipComponent = ({ text, legend, className}) => {
  return (
    <Tooltip title={legend} className={className} enterTouchDelay>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {text}
        <InfoIcon
          style={{
            color: "gray",
            marginLeft: '5px',
          }}
        />
      </div>
    </Tooltip>
  )
};


export default TooltipComponent;
