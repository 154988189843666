import { useEffect, useState, useContext } from "react";
import { ProposalDenyService } from '../../services';
import { ClientContext } from '../../context/client';
import Div from './style';
import Button from '../Button';
import Popup from './Pop-up';
import TimerBox from '../TimerBox';
import { useHistory } from "react-router";

import Feedback from "../../components/Feedback";

const REASON = {
  reason1: {
    value: "HIGH_VALUE",
    desc: "O valor está muito alto para pagar",
  },
  reason2: {
    value: "MORE_INSTALLMENTS",
    desc: "Gostaria de mais parcelas",
  },
  reason3: {
    value: "BAD_DUE_DATE",
    desc: "Não consigo pagar até o vencimento ofertado",
  },
  reason4: {
    value: "NON_TRUSTED",
    desc: "Não senti segurança em negociar",
  },
  reason5: {
    value: "OTHER",
    desc: "Outro motivo",
  },
};

const STEP = {
  1: "STARTED_REFUSE",
  2: "WARNED",
  3: "SENT_REASON",
};

export default function ProposalDeny({ popup, close }) {
  const { client } = useContext(ClientContext);
  const [reason, setReason] = useState();
  const [isOtherReason, toggleIsOtherReason] = useState(false);
  const [otherReason, setOtherReason] = useState("");
  const [proposalId, setProposalId] = useState(null);
  const [step, nextStep] = useState(1);
  
  const [timer, setTimer] = useState(false);

  let history = useHistory();

  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (!proposalId) {
          const body = {
            clientId: client.clientId,
            step: STEP[step],
            status: "PENDING",
          };

          const { data  } = await ProposalDenyService.insertDenyProposal(client.partnerId, body);

          setProposalId(data.insertId);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [client, step, proposalId]);

  const selectReason = async (e) => {
    const reasonValue = e.target.value;

    setReason(reasonValue);
    if (reasonValue === "OTHER") {
      toggleIsOtherReason(true);
    } else {
      toggleIsOtherReason(false);
    }
  };

  const onButtonToNextStep = async () => {
    const body = {
      step: STEP[step + 1],
      status: "PENDING",
      type: reason,
      other: otherReason || "empty",
    };

    nextStep((prevState) => prevState + 1);
    
    await ProposalDenyService.updateDenyProposal(client.partnerId, proposalId, body);
  };

  const renderStep1 = () => (
    <div>
      <h2>Tudo bem, queremos te ajudar!</h2>
      <div className="desc font-dossis-medium">
        Por favor informe o motivo que impede que você proceda com a negociação:
      </div>
      {Object.entries(REASON).map(([key, rea]) => (
        <label htmlFor={key} key={key}>
          <input
            id={key}
            type="radio"
            name="reason"
            checked={reason === rea.value}
            value={rea.value}
            onClick={selectReason}
            className="font-dossis-medium"
          />
          <span>{rea.desc}</span>
        </label>
      ))}
      {isOtherReason ? (
      <label className="reason-other font-dossis-medium">
        <span>Motivo:</span>
        <textarea placeholder="Descreva em poucas palavras para que possamos entender como te ajudar" value={otherReason} onChange={(e) => setOtherReason(e.target.value)}/>
      </label>
      ) : <div className="space" />}
  </div>
  );

  const renderStep2 = () => (
    <div className="font-dossis-medium">
      <h2 className="font-dossis-medium">Atenção!</h2>
      <div className="attention">
        <TimerBox creditor={client?.creditorId} timer={timer} setTimer={setTimer}/>
      </div>
      <h2 className="font-dossis-medium">
        Caso recuse esta proposta, você perderá a condição especial e será
        acrescentado o valor corrigido dos juros do débito
      </h2>
    </div>
  );

  const renderFeedback = () => {
    return (
      <Feedback 
        showFeedback={showFeedback}
        setShowFeedback={setShowFeedback}
        clientId={client?.clientId}
        partnerId={client?.partnerId}
      />
    )
  }

  useEffect(() => { 
    if(step === 3) setShowFeedback(true) 
  },[step]);

  const renderProposalDeny = () => (
    <Div creditor={client?.creditorId} style={{justifyContent: 'center', alignItems: 'center' }}>
      {step === 1 && renderStep1()}
      {step === 2 && renderStep2()}
      {step !== 3 ? (
      <>
        <Button
          color="primary"
          size="large"
          onClick={onButtonToNextStep}
          text={step === 1 ? "Enviar Motivo" : "Concordar e Finalizar"}
          disabled={ (reason === 'OTHER' ? otherReason.trim() === '' : !reason) }
          fontFamily='Dosis-semibold'
          className="primary"
        />
        <Button
          color="secondary"
          size="large"
          onClick={() => {close(false)}}
          text={step === 1 ? "Voltar" : "Voltar para a negociação"}
          fontFamily='Dosis-semibold'
          className="secondary"
          creditor={client?.creditorId}
        />
      </>
      ) : (
        <div>
          <h2 className="font-dossis-medium">Obrigado!</h2>
          <div className="desc">
            Sua resposta foi enviada e em breve um consultor irá entrar em
            contato com você para te ajudar a resolver essa pendência!
          </div>
          <Button
            color="primary"
            size="large"
            onClick={() => history.push('')}
            text="Sair"
            fontFamily='Dosis-semibold'
            className="primary"
          />
        </div>
      )}
      {showFeedback && renderFeedback()}
    </Div>
  );

  return (<Popup>{renderProposalDeny()}</Popup>);
};
