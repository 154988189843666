import styled from "styled-components";
import theme from "../../../theme";

export const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    

    .clientName {
        display: flex;
        font-size: ${theme.text.size.xl};
    }

    .tooltip {
        align-self: flex-end;
    }

    input {
        text-align: center;
        width: 100%;
    }
    input::placeholder {
        text-align: center;
    }

    .about {
        background: transparent;
        border: none;
        color: ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].primary : theme.creditor['0'].primary};
        margin: 20px auto;
        text-align: center;
        cursor: pointer;
    }

    button {
        background-color:  ${({theme, creditor}) => creditor && theme.creditor[creditor] ? theme.creditor[creditor].primary : theme.creditor['0'].primary};
    }
`;